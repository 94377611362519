import { useEffect, useState } from 'react';
import { FilteredQueryResult } from '../types';
import Loader from './loader';

/**
 * A convinience hook for mutating the global
 * loader state in response to graphql results.
 *
 * Once the query results are all loaded successfully, this handles taking down
 * the global loader.
 *
 * If any of the results errored out, this handles showing a generic error
 * message.
 *
 * @param vals An array of `FilteredQueryResult` objects to watch. Any Apollo query result
 * is also a `FilteredQueryResult` so it can be passed into the array.
 */
const useWaitFor = (vals: Array<FilteredQueryResult<any>>): void => {
  const { stopLoad, startLoad } = Loader.useContainer();
  const [hasRun, setHasRun] = useState(false);
  useEffect(() => {
    if (
      !hasRun &&
      vals.every(v => v.data !== undefined && v.loading === false)
    ) {
      setHasRun(true);
      stopLoad();
    }

    if (vals.some(v => v.error !== undefined)) {
      startLoad('error');
    }
  }, [stopLoad, hasRun, ...vals]); // eslint-disable-line
};

export default useWaitFor;
