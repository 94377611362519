import { Screens, useCart, useEntries, useNavigate } from '.';

const useCounter = (): [number, number] => {
  const { currentScreen } = useNavigate();
  const screens = Screens.useContainer();
  const { cartItems } = useCart();
  const { entries } = useEntries();
  return screens.manager.getCounter(
    entries,
    cartItems,
    currentScreen?.pathname
  );
};

export default useCounter;
