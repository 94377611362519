import { useContainer } from 'unstated-next';
import { Session } from './';
import { useFirebase } from './firebase';
import { useUpdateCart } from './cart';
import { useUpdateEntries } from './entries';
import Loader from '../state/loader';

export default function useReset(redirectTo?: string): () => Promise<void> {
  const session = useContainer(Session);
  const { startLoad } = Loader.useContainer();
  const firebase = useFirebase();
  const { emptyCart } = useUpdateCart();
  const { clearEntries } = useUpdateEntries();
  const reset = async (): Promise<void> => {
    startLoad();
    // Clear state
    emptyCart();
    clearEntries();
    // Signs the user out of their account and into sage anonymously
    await firebase.doSignIntoSageAnonymously();
    // Signs out of firestore
    await session.reset();
    // Redirect back to starting screen

    if (redirectTo) {
      window.location.href = redirectTo;
    }
  };
  return reset;
}
