import { useState, useCallback } from 'react';
import { createContainer, useContainer } from 'unstated-next';
import { AnyCartItem, ProductKey, CartItem, CartItemInput } from '../types';
import { PapiBillingPeriod } from '../types/papi-global-types';
import sleep from '../lib/sleep';
import track, { identify } from '@parsleyhealth/cilantro-track';

const uniqueId = (): string =>
  Math.random()
    .toString(36)
    .substr(2, 9);

type RemoveFromCart = { (itemId: string): Promise<void> };
type EmptyCart = { (): Promise<void> };

interface UseCartContainer {
  cartItems: Array<AnyCartItem>;
  emptyCart: EmptyCart;
  removeFromCart: RemoveFromCart;
  addToCart<K extends ProductKey>(
    cartItemInput: CartItemInput<K>
  ): Promise<void>;
  editCartItem<K extends ProductKey>(
    itemId: string,
    cartItemInput: Partial<CartItemInput<K>>
  ): Promise<void>;
}

type UseUpdateCart = Omit<UseCartContainer, 'cartItems'>;

async function trackCartItem(cartItem: CartItem<any>): Promise<void> {
  const billingPeriod =
    'billingPeriod' in cartItem.params
      ? cartItem.params.billingPeriod
      : undefined;

  // TODO: make sure this change doesn't break the funnel
  // Identify first to tag following events for funnel analysis
  await identify({
    product: cartItem.productType,
    currentCartItem: cartItem.productType,
    paymentScheduleType:
      billingPeriod === PapiBillingPeriod.MONTH ? 'Monthly' : 'Upfront'
  });

  // Make sure identify happens before track
  await sleep(500);

  track('Product Added To Cart', {
    product: cartItem.productType
  });

  if (billingPeriod) {
    track('Payment Schedule Selected', {
      product: cartItem.productType,
      paymentScheduleType:
        billingPeriod === PapiBillingPeriod.MONTH ? 'Monthly' : 'Upfront'
    });
  }
}

function useCartContainer(
  initialState = [] as Array<AnyCartItem>
): UseCartContainer {
  const [cartItems, setCartItems] = useState<Array<AnyCartItem>>(initialState);

  const emptyCart = useCallback(async (): Promise<void> => {
    setCartItems([]);
  }, []);

  const removeFromCart = useCallback(
    async (itemId: string): Promise<void> => {
      setCartItems(cartItems.filter(item => item.id !== itemId));
    },
    [cartItems]
  );

  const editCartItem = useCallback(
    async function<K extends ProductKey>(
      itemId: string,
      cartItemInput: Partial<CartItemInput<K>>
    ): Promise<void> {
      const newCartItems = cartItems.map(item => {
        if (item.id === itemId) {
          const updatedCartItem: CartItem<K> = Object.assign(
            {},
            item,
            cartItemInput
          );
          return updatedCartItem;
        }
        return item;
      });
      setCartItems(newCartItems as Array<AnyCartItem>);
      await trackCartItem(newCartItems[0]);
    },
    [cartItems]
  );

  const addToCart = useCallback(
    async function<K extends ProductKey>(
      cartItemInput: CartItemInput<K>
    ): Promise<void> {
      const newItem: CartItem<K> = {
        id: uniqueId(),
        purchaseID: null,
        productKey: cartItemInput.productKey,
        productType: cartItemInput.productKey,
        params: cartItemInput.params,
        planID: cartItemInput.planID,
        planName: cartItemInput.planName,
        isWaitlisted: cartItemInput.isWaitlisted,
        slug: cartItemInput.slug
      };

      const newCartItems = [...cartItems, newItem];

      setCartItems(newCartItems as Array<AnyCartItem>);
      await trackCartItem(newItem);
    },
    [cartItems]
  );

  return { cartItems, emptyCart, removeFromCart, addToCart, editCartItem };
}

export const CartContainer = createContainer(useCartContainer);

export function useCart(): { cartItems: Array<AnyCartItem> } {
  const { cartItems } = useContainer(CartContainer);
  return { cartItems };
}

export function useUpdateCart(): UseUpdateCart {
  const { emptyCart, removeFromCart, addToCart, editCartItem } = useContainer(
    CartContainer
  );
  return { emptyCart, removeFromCart, addToCart, editCartItem };
}
