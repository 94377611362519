import React, { useState, useEffect, useContext } from 'react';
import { Firebase } from '../lib/firebase';
import queryString from 'query-string';
import { QueryParams } from '../types';
import firebase from 'firebase';

interface FirebaseUserState {
  isLoggedIn: boolean;
  firebaseSageUser: firebase.User | undefined | null;
}

const FirebaseContext = React.createContext<Firebase>({} as Firebase);
const FirebaseUserContext = React.createContext<FirebaseUserState>(
  {} as FirebaseUserState
);

const getExpirationDate = (): string => {
  const expiryPeriod = 60 * 60 * 1000; // 1 hour expiry in milliseconds
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + expiryPeriod;
  now.setTime(expireTime);
  return now.toUTCString();
};

export const FirebaseProvider: React.FC = props => {
  const [firebase, setFirebase] = useState<Firebase>();
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();
  useEffect(() => {
    if (!firebase) {
      setFirebase(new Firebase());
    }
  }, [firebase]);

  useEffect(() => {
    if (firebase) {
      const params: QueryParams = queryString.parse(window.location.search);

      if (params.token) {
        firebase
          .doSignIntoSageWithCustomToken(params.token)
          .then(() => {
            setCurrentUser(firebase.sageAuth.currentUser);
            document.cookie = `insightsToken=${
              params.token
            };domain=parsleyhealth.com;expires=${getExpirationDate()};`;
          })
          .catch(e => {
            console.log('Sign-in error', e);
          });
      }
    }
  }, [firebase]);

  if (firebase) {
    const userContext = {
      firebaseSageUser: currentUser,
      isLoggedIn: currentUser !== null && !currentUser?.isAnonymous
    };

    return (
      <FirebaseContext.Provider value={firebase}>
        <FirebaseUserContext.Provider value={userContext}>
          {props.children}
        </FirebaseUserContext.Provider>
      </FirebaseContext.Provider>
    );
  }

  return null;
};

export const useFirebase = (): Firebase => useContext(FirebaseContext);
export const useFirebaseUser = (): FirebaseUserState =>
  useContext(FirebaseUserContext);
