import React from 'react';
import { TermsLink } from '../atoms/terms-link';

type SeparatedTermsProps = {
  terms: { href: string; label: string; hide?: boolean }[];
  separator?: string;
};

export const SeparatedTerms: React.FC<SeparatedTermsProps> = ({
  terms,
  separator = 'and'
}) => {
  // Remove any hide term from the array
  const filteredTerms = terms?.filter(term => Boolean(!term.hide));

  if (!filteredTerms) return null;

  // If there's only one term, just render it without any commas or "and"
  if (filteredTerms.length === 1) {
    return (
      <TermsLink href={filteredTerms[0].href} label={filteredTerms[0].label} />
    );
  }

  // Otherwise, get the last term and all the rest of the terms
  const lastIndex = filteredTerms.length - 1;
  const lastTerm = filteredTerms[lastIndex];
  const restOfTerms = filteredTerms.slice(0, lastIndex);

  // Render the terms as an ordered list with commas between all but the last two
  return (
    <>
      {restOfTerms.map((term, i) => (
        <React.Fragment key={term.href}>
          <TermsLink href={term.href} label={term.label} />
          {i < lastIndex - 1 ? <span>, </span> : null}
        </React.Fragment>
      ))}
      <span> {separator} </span>
      <TermsLink href={lastTerm.href} label={lastTerm.label} />
    </>
  );
};
