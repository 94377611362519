import React from 'react';
import { useContainer } from 'unstated-next';
import Loader from '../../../state/loader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import BarLoader from 'react-spinners/BarLoader';
import { Palette, H3, Box } from '@parsleyhealth/cilantro-ui';
import { OpenBeacon } from '../../../lib/help-scout';
import styles from './loader-overlay.module.css';
import clsx from 'clsx';
import { P1 } from '@ph-bit/design.ui.typography.p1';
import { H2 } from '@ph-bit/design.ui.typography.h2';
import { Subtitle } from '@ph-bit/design.ui.typography.subtitle';

const transition = (loading: boolean): {} => ({
  transition: loading
    ? 'opacity 0.5s, visibility 0.0s'
    : 'opacity 0.5s, visibility 0.0s 0.5s'
});

const LoaderOverlay: React.FC = () => {
  const { loading, loaderType, stopLoad } = useContainer(Loader);

  if (loaderType === 'error') {
    return (
      <div
        className={clsx(
          styles.wrapper,
          loading ? 'ph-visible ph-opacity-100' : 'ph-opacity-0 ph-invisible'
        )}
        style={transition(loading)}
      >
        <div className={styles.content}>
          <p className="ph-text-4xl ph-text-dark-green ph-mb-4 ph-leading-relaxed ph-font-semibold">
            Whoops, we encountered a problem.
          </p>
          <div className="ph-mx-auto ph-max-w-lg ph-flex ph-flex-col ph-items-center">
            <p className="ph-text-lg ph-text-dark-green ph-mb-8">
              We’re sorry, something went wrong. If you continue to have issues,
              please contact{' '}
              <span
                onClick={OpenBeacon}
                className="ph-text-sage ph-underline ph-cursor-pointer"
              >
                Member Experience
              </span>
              .
            </p>

            <button
              className="ph-border ph-border-sage ph-bg-transparent ph-flex ph-items-center ph-justify-center ph-w-56 ph-h-16 ph-rounded-full ph-text-sage ph-text-base ph-cursor-pointer"
              onClick={() => stopLoad()}
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (loaderType === 'purchase') {
    return (
      <div
        className={clsx(
          styles.wrapper,
          loading ? 'ph-visible ph-opacity-100' : 'ph-opacity-0 ph-invisible'
        )}
        style={transition(loading)}
      >
        <Box mb="s" className="ph-text-center">
          <H3>Processing your order</H3>
          <P1>Please do not refresh the page to avoid duplicate orders</P1>
        </Box>
        <BarLoader width={220} height={4} color={Palette.emerald3} />
      </div>
    );
  }

  if (loaderType === 'insurance-rte') {
    return (
      <div
        className={clsx(
          styles.wrapper,
          loading ? 'ph-visible ph-opacity-100' : 'ph-opacity-0 ph-invisible',
          '!justify-start'
        )}
        style={transition(loading)}
      >
        <div className="w-full md:w-auto">
          <div className="mt-24 flex flex-col items-center px-5">
            <BarLoader width={'100%'} height={4} color={Palette.emerald3} />
            <div className="ph-text-center flex flex-col gap-4 mt-8">
              <H2 className="text-[29px]">Checking your insurance</H2>
              <Subtitle>This will just take a minute</Subtitle>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.wrapper,
        loading ? 'ph-visible ph-opacity-100' : 'ph-opacity-0 ph-invisible'
      )}
      style={transition(loading)}
    >
      <PropagateLoader size={10} color={Palette.grayandrules} />
    </div>
  );
};

export default LoaderOverlay;
