import React, { useState, useEffect, createContext, useContext } from 'react';
import { createClient } from '../lib/apollo-client';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { useFirebase, useFirebaseUser } from './firebase';

const UnifiedClientContext = createContext<ApolloClient<any>>(
  {} as ApolloClient<any>
);

export const GqlProvider: React.FC = props => {
  const firebase = useFirebase();
  const firebaseUser = useFirebaseUser();
  const [unifiedClient, setUnifiedClient] = useState<ApolloClient<any>>();
  useEffect(() => {
    if (firebaseUser.firebaseSageUser) {
      setUnifiedClient(createClient({ firebase }));
    }
  }, [firebase, firebaseUser.firebaseSageUser]);

  useEffect(() => {
    if (!unifiedClient) {
      setUnifiedClient(createClient({ firebase }));
    }
  }, [firebase, unifiedClient]);

  if (unifiedClient) {
    return (
      <ApolloProvider client={unifiedClient}>{props.children}</ApolloProvider>
    );
  }
  return null;
};

export const useUnifiedClient = (): ApolloClient<any> =>
  useContext(UnifiedClientContext);
