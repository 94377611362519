import clsx from 'clsx';
import React from 'react';

interface ColumProps {
  className?: string;
}

const Column: React.FC<ColumProps> = ({ children, className }) => (
  <div
    className={clsx(
      'ph-block',
      'ph-px-8',
      'ph-mx-auto',
      'sm:ph-flex',
      'sm:ph-px-3',
      'sm:ph-flex-col',
      'sm:ph-justify-start',
      'sm:ph-items-stretch',
      'sm:ph-max-w-lg',
      className
    )}
  >
    {children}
  </div>
);

export default Column;
