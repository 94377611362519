import React from 'react';
import './temp-legal-footer.css';

export const TempLegalFooter: React.FC = () => {
  return (
    <footer id="legal-footer" role="contentinfo" data-testid="legal-footer">
      <span data-testid="copyright">© 2023 Parsley Health</span>
      <ul>
        <li>
          <a
            data-testid="terms-of-use"
            href="https://www.parsleyhealth.com/terms-of-use/"
          >
            Terms of Use
          </a>
        </li>
        <li>
          <a
            data-testid="clinical-membership-terms"
            href="https://www.parsleyhealth.com/clinical-membership-terms/"
          >
            Clinical Membership Terms &amp; Conditions
          </a>
        </li>
        <li>
          <a
            data-testid="privacy-policy"
            href="https://www.parsleyhealth.com/privacy/"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            data-testid="notice-of-privacy-practices"
            href="https://www.parsleyhealth.com/notice-of-privacy-practices/"
          >
            Notice of Privacy Practices
          </a>
        </li>
      </ul>
    </footer>
  );
};
