import React from 'react';
import clsx from 'clsx';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface LinkProps extends Props {
  useCustomUnderline?: boolean;
  noUnderline?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  children,
  className,
  noUnderline,
  useCustomUnderline,
  ...anchorProps
}) => (
  <a
    className={clsx(
      className,
      'ph-text-sage',
      'ph-cursor-pointer',
      noUnderline ? 'ph-no-underline' : 'ph-underline',
      useCustomUnderline &&
        'ph-inline-block ph-no-underline ph-border-b-2 ph-border-sage ph-border-solid'
    )}
    {...anchorProps}
  >
    {children}
  </a>
);

export default Link;
