export const MY_PARSLEY_URL =
  process.env.REACT_APP_ACTIVE_ENV === 'production'
    ? 'https://my.parsleyhealth.com'
    : 'https://my.staging.parsleyhealth.com';
// URL of the main website. Defaults to prod link.
export const WWW_URL =
  process.env.REACT_APP_WWW_URL || 'https://www.parsleyhealth.com';
export const JOIN_PARSLEY_URL = WWW_URL + '/membership';
export const BLOG_URL = WWW_URL + '/library';
export const CancellationPolicy = WWW_URL + '/faq/#how-do-i-cancel-my-plan';
export const BUY_COMLPETE_CARE = '/buy/complete-care';
export const QUESTIONS_PHONE_NUMBER = '3329004403';
