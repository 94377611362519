import React, { useState } from 'react';
import { createContainer } from 'unstated-next';
import { validateCoupon_validateCoupon } from '../api/generated-types/validateCoupon';

type TValidatedCouponResult = Partial<validateCoupon_validateCoupon> | null;
type TValidatedCoupon = [
  TValidatedCouponResult,
  React.Dispatch<React.SetStateAction<TValidatedCouponResult>>
];

const useValidatedCoupon = (): TValidatedCoupon => {
  const [couponValidationResult, setCouponValidationResult] = useState<
    TValidatedCouponResult
  >(null);
  return [couponValidationResult, setCouponValidationResult];
};

const ValidatedCoupon = createContainer(useValidatedCoupon);

export default ValidatedCoupon;
