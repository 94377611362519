import React, { useEffect, useState } from 'react';
import { Session, EntriesContainer, CartContainer } from '../../state';
import { EntriesData, Cart } from '../../types';
import { getProductKeys } from '../../lib/products';
/**
 * Populates the state containers with firestore data
 * @param props
 */
const SessionHydrator: React.FC = props => {
  const session = Session.useContainer();
  const [initialEntries, setInitialEntries] = useState<Partial<
    EntriesData
  > | null>(null);
  const [initialCart, setInitialCart] = useState<Cart | null>(null);

  // This effect waits for the session user to be created
  // before setting the cart and entries state, so we can load
  // the persisted state from a firestore record, if it exists.
  // If a record does not exist (i.e. a first time user), we set
  // the cart and entires to empty objects. The record will
  // be created when the user submits the welcome screen form.
  useEffect(() => {
    if (session.fsUserRef && initialEntries === null) {
      if (session.hasSessionRecord) {
        const userRef = session.fsUserRef;
        userRef.get().then(doc => {
          const data = doc.data() as EntriesData & { cart?: Cart };
          const { cart, ...entries } = data;
          setInitialEntries(entries);

          // Sanitizes the cart data
          const cleanedCart = cart?.filter(cartItem => {
            // Makes sure the cart item's product key is still current
            if (!getProductKeys().includes(cartItem.productKey)) {
              return false;
            }
            return true;
          });

          setInitialCart(cleanedCart ? cleanedCart : []);
        });
      } else {
        setInitialEntries({});
        setInitialCart([]);
      }
    }
  }, [
    initialEntries,
    initialCart,
    session.fsUserRef,
    session.user,
    session.hasSessionRecord,
    session
  ]);

  if (!initialEntries || !initialCart) {
    return null;
  }

  return (
    <EntriesContainer.Provider initialState={initialEntries}>
      <CartContainer.Provider initialState={initialCart}>
        {props.children}
        <Persistor />
      </CartContainer.Provider>
    </EntriesContainer.Provider>
  );
};

/**
 * Peristor's job is to save the cart and entries state
 * on every change.
 */
const Persistor: React.FC = () => {
  const session = Session.useContainer();
  const { entries } = EntriesContainer.useContainer();
  const { cartItems } = CartContainer.useContainer();

  useEffect(() => {
    if (session.fsUserRef && session.hasSessionRecord) {
      // Firstore doesn't allow submitting 'undefined' as a value,
      // so this filters out the undefined properties from the object
      const data = { ...entries, lastUsage: new Date().toISOString() };
      const keys = Object.keys(entries) as Array<keyof EntriesData>;
      keys.forEach(key => {
        if (data[key] === undefined) {
          delete data[key];
        }
      });
      // Special rules
      delete data['password'];
      session.fsUserRef.update(data);
    }
  }, [session.fsUserRef, entries, session.hasSessionRecord]);

  useEffect(() => {
    if (session.fsUserRef && session.hasSessionRecord) {
      session.fsUserRef.update({
        cart: cartItems
      });
    }
  }, [session.fsUserRef, cartItems, session.hasSessionRecord]);

  return null;
};

export default SessionHydrator;
