import { Screens, useCart, useEntries, useNavigate } from '.';

const useProgress = (): number => {
  const screens = Screens.useContainer();
  const { currentScreen } = useNavigate();
  const { cartItems } = useCart();
  const { entries } = useEntries();
  return screens.manager.getProgress(
    entries,
    cartItems,
    currentScreen?.pathname
  );
};

export default useProgress;
