import {
  PapiPlan,
  AnyCartItem,
  PapiAvailableCenters,
  PapiCenter
} from '../types';
import getAvailableCentersForProductSelection from './get-available-centers-for-product-selection';

function getSelectedCenter(
  selectedCenterID: string | undefined,
  cartItem: AnyCartItem | undefined,
  allPlans: PapiPlan[],
  availableCenters: PapiAvailableCenters
): PapiCenter | undefined {
  if (cartItem) {
    // Check if there is only
    // a single possible option for the availability. If so, we assume
    // it's the selected center.
    const centers = getAvailableCentersForProductSelection(
      cartItem,
      availableCenters,
      allPlans
    );
    if (centers.length === 1) {
      return centers[0].center;
    }
    // Otherwise see if we've made a center selection, and make sure
    // the center exists in our available centers to be safe.
    if (selectedCenterID) {
      const availability = centers.find(a => a.center.id === selectedCenterID);

      return availability?.center;
    }
  }

  return undefined;
}

export default getSelectedCenter;
