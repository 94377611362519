import { useMemo } from 'react';
import { Selection } from '../types';
import { PapiBillingPeriod } from '../types/papi-global-types';
import {
  CCY1_MONTH_199,
  CCY1_YEAR_2189,
  V_CCY1_MONTH_199,
  V_CCY1_YEAR_2189
} from '../constants/hard-coded-plan-ids';

const isProd = process.env.REACT_APP_ACTIVE_ENV === 'production';

type TUseGetAlternateBillingPeriodPlan = {
  planId: string | undefined;
};

type billingPeriodOptionsType = Partial<Record<PapiBillingPeriod, string>>[];

//@TODO This array of objects will come from sanity
const billingPlans: billingPeriodOptionsType = [
  //Variant 1
  {
    MONTH: CCY1_MONTH_199,
    YEAR: CCY1_YEAR_2189
  },
  {
    MONTH: V_CCY1_MONTH_199,
    YEAR: V_CCY1_YEAR_2189
  }
];

const prodBillingPlans: billingPeriodOptionsType = [...billingPlans];

const stagingBillingPlans: billingPeriodOptionsType = [...billingPlans];

const filteredBillingPlans = isProd ? prodBillingPlans : stagingBillingPlans;

const selectDefaultBillingPeriod = (
  currentBillingPeriod: PapiBillingPeriod
): PapiBillingPeriod => {
  const billingPeriodWhiteList = [
    PapiBillingPeriod.YEAR,
    PapiBillingPeriod.MONTH
  ];
  const filteredBillingPeriods = billingPeriodWhiteList.filter(
    period => currentBillingPeriod !== period
  );
  return filteredBillingPeriods[0];
};

const useGetAlternateBillingPeriodPlanId = (
  { plan: currentPlan }: Selection,
  desiredBillingPeriod: PapiBillingPeriod = selectDefaultBillingPeriod(
    currentPlan.billingPeriod
  )
): TUseGetAlternateBillingPeriodPlan => {
  const currentBillingPeriod = currentPlan.billingPeriod;

  const planId = useMemo(() => {
    if (currentBillingPeriod === desiredBillingPeriod) return;
    const billingPeriodObject = filteredBillingPlans.find(
      k =>
        k[currentBillingPeriod as keyof typeof k]?.trim() ===
        currentPlan.id.trim()
    );
    if (billingPeriodObject === undefined) return;
    const desiredPlanId =
      billingPeriodObject[
        (desiredBillingPeriod as unknown) as keyof typeof billingPeriodObject
      ];
    return desiredPlanId;
  }, [currentPlan, currentBillingPeriod, desiredBillingPeriod]);

  return {
    planId
  };
};

export default useGetAlternateBillingPeriodPlanId;
