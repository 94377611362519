import React from 'react';
import Icon, { IconProps } from '../icon';

export const CircleStep: React.FC<IconProps> = props => {
  return (
    <Icon width={8} height={8} ariaLabel="circle step" {...props}>
      <path d="M8 4.007C8 6.2028 6.73754 8.20682 4.01622 7.98285C1.2949 7.75887 0.34104 6.30292 0.0324426 4.007C-0.276155 1.71108 1.65962 -0.274287 4.01622 0.0311586C6.17644 0.311148 8 1.8112 8 4.007Z" />
    </Icon>
  );
};
