import { ProductPropsMap, UrlScreens } from '../types';
import { PapiBillingPeriod, PapiProductType } from '../types/papi-global-types';
import { completeCareFlow, completeCareRTEFlow } from './flows';

const productProps: ProductPropsMap = {
  [PapiProductType.COMPLETE_CARE_Y1]: {
    slugs: [UrlScreens.completeCare],
    productType: PapiProductType.COMPLETE_CARE_Y1,
    virtualProductType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        flow: completeCareFlow,
        slug: UrlScreens.completeCare
      }
    ]
  },
  [PapiProductType.VIRTUAL_COMPLETE_CARE_Y1]: {
    slugs: [UrlScreens.completeCare],
    productType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    virtualProductType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        flow: completeCareFlow,
        slug: UrlScreens.completeCare
      }
    ]
  },
  [PapiProductType.PEDIATRICS]: {
    slugs: [UrlScreens.pediatrics],
    productType: PapiProductType.PEDIATRICS,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        slug: UrlScreens.pediatrics,
        flow: completeCareFlow
      }
    ]
  }
};

const productRTEProps: ProductPropsMap = {
  [PapiProductType.COMPLETE_CARE_Y1]: {
    slugs: [UrlScreens.completeCare],
    productType: PapiProductType.COMPLETE_CARE_Y1,
    virtualProductType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        flow: completeCareRTEFlow,
        slug: UrlScreens.completeCare
      }
    ]
  },
  [PapiProductType.VIRTUAL_COMPLETE_CARE_Y1]: {
    slugs: [UrlScreens.completeCare],
    productType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    virtualProductType: PapiProductType.VIRTUAL_COMPLETE_CARE_Y1,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        flow: completeCareRTEFlow,
        slug: UrlScreens.completeCare
      }
    ]
  },
  [PapiProductType.PEDIATRICS]: {
    slugs: [UrlScreens.pediatrics],
    productType: PapiProductType.PEDIATRICS,
    initialParams: {
      billingPeriod: PapiBillingPeriod.MONTH
    },
    flows: [
      {
        slug: UrlScreens.pediatrics,
        flow: completeCareRTEFlow
      }
    ]
  }
};

export { productProps, productRTEProps };
