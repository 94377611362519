import { MINIMUM_PASSWORD_LENGTH } from './password-requirements';

export const REQUIRED_FIELD_ERROR = 'Required';

export const PASSWORD_MINIMUM_LENGTH_ERROR = `Requires ${MINIMUM_PASSWORD_LENGTH} characters minimum.`;

export const PASSWORD_MALFORMED_ERROR =
  'Password must have at least one symbol, one uppercase letter, one lowercase letter and a number.';

export const PASSWORD_INPUT_TOOLTIP_MESSAGE = `${PASSWORD_MINIMUM_LENGTH_ERROR} ${PASSWORD_MALFORMED_ERROR}`;

export const PASSWORD_PWNED_ERROR =
  'This password was found in a publicly available database of leaked passwords. Please provide a newly created password for exclusive use with Parsley Health.';
