import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import gql from 'graphql-tag';

import {
  listAvailableInsuranceChanges,
  listAvailableInsuranceChangesVariables
} from './generated-types/listAvailableInsuranceChanges';

const LIST_AVAILABLE_INSURANCE_CHANGES = gql`
  query listAvailableInsuranceChanges($id: ID!) {
    listAvailableInsuranceChanges(planID: $id) {
      paymentRateInCents
      id
      billingPeriod
      insuranceType
      product {
        type
      }
    }
  }
`;

export const useAvailableInsuranceChanges = (
  id: string
): QueryResult<
  listAvailableInsuranceChanges,
  listAvailableInsuranceChangesVariables
> => {
  return useQuery(LIST_AVAILABLE_INSURANCE_CHANGES, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });
};
