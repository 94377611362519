import React, { useEffect } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { screens, screenKeys } from '../../constants/screens';
import ScreenComponents from '../screens';
import TemplateComponents from '../templates';

interface Props extends RouteComponentProps {
  component: React.ComponentType;
  templateName: string;
}

const Route: React.FC<Props> = props => {
  useEffect(() => {
    // Always scroll the window to top when we mount a new screen
    window.scrollTo(0, 0);
  }, []);

  if (props.templateName.toLowerCase() === 'none') {
    return <props.component />;
  }

  const Template = TemplateComponents[props.templateName];

  return (
    <Template>
      <props.component />
    </Template>
  );
};

const ScreenRoutes: React.FC = () => {
  return (
    <React.Suspense fallback={<TemplateComponents.Default />}>
      <Router>
        {screenKeys.map(key => {
          const screen = screens[key];
          const Component = ScreenComponents[screen.componentName];
          const pathname = screen.pathname;
          return (
            <Route
              key={pathname}
              path={pathname}
              component={Component}
              templateName={screen.templateName || 'Default'}
            />
          );
        })}
      </Router>
    </React.Suspense>
  );
};

export default ScreenRoutes;
