import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import qs from 'query-string';
import {
  useCart,
  useUpdateCart,
  useUpdateEntries,
  useNavigate,
  useAllPlans,
  useFirebase
} from '../../state';
import queryString from 'query-string';

import {
  getProductKeysFromSlug,
  getProductProps,
  getPlanForProductSelection
} from '../../lib/products';
import { AnyProductSelection, QueryParams, PapiPlan } from '../../types';
import {
  PapiBillingPeriod,
  PapiInsuranceType,
  PapiSex
} from '../../types/papi-global-types';
import { useCurrentPerson } from '../../api';
import { getCheckoutFlow } from '../../lib/get-checkout-flow';

/**
 * This component is the page the user lands on when they preselect
 * an item from www. It adds an item to your cart based on the
 * "product" query parameter.
 */
const Buy: React.FC<RouteComponentProps<{
  product: string;
}>> = props => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const { location, product } = props;
  const { cartItems } = useCart();
  const { addToCart, emptyCart } = useUpdateCart();
  const { returnToLatest } = useNavigate();
  const allPlansResult = useAllPlans();
  const { billing_period, type: insuranceType, payer }: QueryParams = qs.parse(
    location!.search
  );
  let billingPeriod = billing_period ?? PapiBillingPeriod.YEAR;

  if ((window as any).VWOPrePayText) {
    billingPeriod = PapiBillingPeriod.MONTH;
  }

  const { saveEntries, clearEntries } = useUpdateEntries();
  const firebase = useFirebase();
  const currentPersonResult = useCurrentPerson();
  const currentPerson = currentPersonResult.data?.currentPapiPerson;
  const params: QueryParams = queryString.parse(window.location.search);

  // In the case of users coming from the readiness quiz from Insights we send
  //(email, firstName, biologicalSex, dateOfBirth) in order to prepulate data to the form form them
  if (
    params.email ||
    params.firstName ||
    params.biologicalSex ||
    params.birthDate
  ) {
    saveEntries({
      email: params.email,
      firstName: params.firstName,
      biologicalSex: params.biologicalSex,
      dateOfBirth: params.birthDate
    });
  }

  // we need to wait for the new value for "returnToLatest"
  useEffect(() => {
    if (hasInitialized && cartItems.length > 0) {
      returnToLatest();
    }
  }, [cartItems, hasInitialized, returnToLatest]);

  useEffect(() => {
    const initBuy = async (
      productSlug: string,
      allPlans: PapiPlan[],
      billingPeriod?: PapiBillingPeriod
    ): Promise<void> => {
      // Make sure we don't fire twice
      setHasInitialized(true);
      const productKey = getProductKeysFromSlug(productSlug);
      // No existing product with that slug, do nothing
      if (productKey === undefined) {
        returnToLatest();
        return;
      }

      // Add the product to cart
      if (productKey !== undefined) {
        let params =
          billingPeriod &&
          Object.values(PapiBillingPeriod).includes(billingPeriod)
            ? {
                billingPeriod,
                insuranceType: insuranceType ?? null,
                insurancePayer: payer ?? null
              }
            : getProductProps(productKey).initialParams;
        const plan = getPlanForProductSelection(
          {
            productKey,
            params
          } as AnyProductSelection,
          allPlans
        );

        if (!plan) {
          throw Error('Plan not found in /buy');
        }

        const queryParams: QueryParams = queryString.parse(
          window.location.search
        );
        let newEntries = {};

        // April 2021 split test. Grabbing variant and storing it in local storage so we can use it later
        if (queryParams.variant) {
          window.localStorage.setItem('SplitTestVariant', queryParams.variant);
        }

        if (queryParams.coupon) {
          newEntries = { ...newEntries, ...{ couponCode: queryParams.coupon } };
        }

        params = {
          ...params,
          checkoutFlow: getCheckoutFlow()
        };

        if (queryParams.type) {
          newEntries = {
            ...newEntries,
            ...{
              insuranceType:
                insuranceType === PapiInsuranceType.IN_NETWORK
                  ? PapiInsuranceType.IN_NETWORK
                  : PapiInsuranceType.CASH_PAY
            }
          };
        }

        if (queryParams.payer) {
          newEntries = {
            ...newEntries,
            ...{
              insurancePayer: payer
            }
          };
        }

        if (queryParams.utm_campaign) {
          newEntries = {
            ...newEntries,
            ...{
              utmCampaign: queryParams.utm_campaign,
              utmContent: queryParams.utm_content,
              utmMedium: queryParams.utm_medium,
              utmSource: queryParams.utm_source
            }
          };
        }

        if (queryParams.condition) {
          newEntries = {
            ...newEntries,
            condition: queryParams.condition
          };
        }

        if (firebase.sageAuth && currentPerson && queryParams.token) {
          newEntries = {
            ...newEntries,
            email: currentPerson.email,
            stateCode: currentPerson.addressState || undefined,
            firstName: currentPerson.firstName,
            lastName: currentPerson.lastName,
            dateOfBirth: currentPerson.dateOfBirth || undefined,
            centerID: currentPerson.center?.id,
            biologicalSex: currentPerson.biologicalSex as PapiSex,
            addressPostalCode: currentPerson.addressPostalCode || undefined,
            dapiPersonID: currentPerson.id,
            continue: true
          };
        }

        newEntries = {
          ...newEntries,
          comingFromPlanRoute: false
        };

        saveEntries(newEntries);
        await addToCart({
          params,
          productKey,
          productType: plan.productType,
          planID: plan.id,
          planName: plan.displayName,
          purchaseID: null,
          isWaitlisted: false,
          slug: productSlug
        });
      }
    };
    if (
      !hasInitialized &&
      product &&
      allPlansResult.data &&
      cartItems.length === 0
    ) {
      initBuy(product, allPlansResult.data, billingPeriod);
    }
  }, [
    addToCart,
    allPlansResult.data,
    billingPeriod,
    cartItems,
    cartItems.length,
    currentPerson,
    firebase.sageAuth,
    hasInitialized,
    product,
    returnToLatest,
    saveEntries,
    insuranceType,
    payer
  ]);

  useEffect(() => {
    const cleanUp = async (): Promise<void> => {
      if (!hasInitialized && cartItems.length > 0) {
        await emptyCart();
        await clearEntries();
      }
    };
    cleanUp();
  }, [hasInitialized, cartItems.length, emptyCart, clearEntries]);

  return null;
};

export default Buy;
