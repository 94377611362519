import { navigate } from '@reach/router';
import Screens from './screens';
import { Screen, ScreenKeys } from '../types';
import { REGISTER_PATH } from '../constants/route-names';
import { screens as screensConfig } from '../constants/screens';
import { useEntries, useCart, useUpdateEntries } from './';

interface TUseNavigate {
  currentScreen: Screen | undefined;
  goTo(screenName: ScreenKeys): void;
  next(): void;
  back(): void;
  reset(): void;
  returnToLatest(replace?: boolean): void;
  getScreenIndex(): number;
  getReturningScreenIndex(): number;
  goToLastScreen(): void;
}

const getCurrentPathname = (): string => {
  return window.location.pathname.split(REGISTER_PATH)[1];
};

export default function useNavigate(): TUseNavigate {
  const { entries } = useEntries();
  const { saveEntries } = useUpdateEntries();
  const { cartItems } = useCart();
  const screens = Screens.useContainer();

  const goTo = (screenName: ScreenKeys): void => {
    const screen = screensConfig[screenName];
    navigate(`${REGISTER_PATH}${screen.pathname}`);
  };

  const returnToLatest = (replace = true): void => {
    const screen = screens.manager.getReturningScreen(entries, cartItems);
    if (screen) {
      navigate(`${REGISTER_PATH}${screen.pathname}`, { replace });
    }
  };

  const next = (): void => {
    const currentPath = getCurrentPathname();
    const screen = screens.manager.getScreen(currentPath);
    if (screen !== undefined) {
      const nextScreen = screens.manager.getNextScreen(getCurrentPathname());
      if (nextScreen) {
        navigate(`${REGISTER_PATH}${nextScreen.pathname}`);
      }
    } else {
      returnToLatest(false);
    }
  };

  const back = async (): Promise<void> => {
    const currentPath = getCurrentPathname();

    const prevScreen = screens.manager.getPrevScreen(currentPath);

    if (!prevScreen) return;

    let prevPathname = prevScreen.pathname;

    let newPrevScreen = screens.manager.getPrevScreen(prevPathname);

    while (newPrevScreen && entries.ignoreScreens?.includes(prevPathname)) {
      if (newPrevScreen.pathname) {
        prevPathname = newPrevScreen.pathname;
      }
      newPrevScreen = screens.manager.getPrevScreen(prevPathname);
    }

    await saveEntries({
      ignoreScreens: entries?.ignoreScreens?.filter(
        element => element !== prevPathname
      )
    });

    navigate(`${REGISTER_PATH}${prevPathname}`);
  };

  const reset = (): void => {
    const firstScreen = screens.manager.getStartingScreen();
    navigate(`${REGISTER_PATH}${firstScreen.pathname}`);
  };

  const getReturningScreenIndex = (): number => {
    const screen = screens.manager.getReturningScreen(entries, cartItems);
    if (screen) {
      return screens.manager.getScreenIndex(screen.pathname);
    }
    return -1;
  };

  const getScreenIndex = (): number => {
    const currentPath = getCurrentPathname();
    return screens.manager.getScreenIndex(currentPath);
  };

  const currentScreen = screens.manager.getScreen(getCurrentPathname());

  const goToLastScreen = (): void => {
    const lastScreen = screens.manager.getLastScreen();
    if (lastScreen) {
      navigate(`${REGISTER_PATH}${lastScreen.pathname}`);
    } else {
      returnToLatest(false);
    }
  };

  return {
    currentScreen,
    goTo,
    next,
    back,
    returnToLatest,
    getScreenIndex,
    getReturningScreenIndex,
    reset,
    goToLastScreen
  };
}
