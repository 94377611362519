import gql from 'graphql-tag';
import track from '@parsleyhealth/cilantro-track';
import useRecaptcha from '../state/use-recaptcha';
import { ApolloQueryResult } from 'apollo-client';
import { emailValid } from './generated-types/emailValid';
import { useApolloClient } from '@apollo/react-hooks';
import { useCallback } from 'react';

type TValidateEmail = {
  (email: string): Promise<ApolloQueryResult<emailValid> | undefined>;
};

const EMAIL_VALID = gql`
  query emailValid($email: String!, $recaptcha: PapiRecaptchaInput) {
    emailValid(email: $email, recaptcha: $recaptcha)
  }
`;

const useCheckEmail = (): TValidateEmail => {
  const client = useApolloClient();
  const getToken = useRecaptcha();
  const checkEmail = useCallback(
    (email: string) => {
      async function validateEmail(
        email: string
      ): Promise<ApolloQueryResult<emailValid> | undefined> {
        try {
          if (!getToken) {
            throw new Error('Cannot use recpatcha before initialization.');
          }
          const token = await getToken();
          if (!token) {
            throw new Error('Unable to initialize recpatcha.');
          }
          const result = client.query({
            query: EMAIL_VALID,
            variables: {
              email,
              recaptcha: {
                token,
                ipAddress: 'localhost'
              }
            },
            fetchPolicy: 'network-only'
          });
          return result;
        } catch (err) {
          track('Join Flow Error', { product: undefined, description: err });
          return undefined;
        }
      }
      return validateEmail(email);
    },
    [client, getToken]
  );
  return checkEmail;
};

export default useCheckEmail;
