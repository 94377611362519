/**
 * Util functions for calculating product related data
 */

import {
  ProductKey,
  PapiPlan,
  ProductProps,
  AnyProductSelection,
  PapiCenter
} from '../types';
import { productProps, productRTEProps } from '../constants/product-props';
import { PapiProductType, PapiInsuranceType } from '../types/papi-global-types';
import { PapiPlanExtended } from './../state/use-all-plans';

/**
 * Gets the product properties for a given product key.
 * @param productKey The key
 * @returns Properties of the key
 */
export function getProductProps<K extends ProductKey>(
  productKey: K
): ProductProps<K> {
  const flowsAsList = Object.entries(productProps);
  const productKeyWithFlow = flowsAsList.find(flow =>
    Object.values(flow[1]).find(flowValue => flowValue === productKey)
  );
  if (productKeyWithFlow && productKeyWithFlow[0]) {
    return (productProps[
      productKeyWithFlow[0] as K
    ] as unknown) as ProductProps<K>;
  }
  return (productProps[productKey] as unknown) as ProductProps<K>;
}

export function getRTEProductProps<K extends ProductKey>(
  productKey: K
): ProductProps<K> {
  const flowsAsList = Object.entries(productRTEProps);
  const productKeyWithFlow = flowsAsList.find(flow =>
    Object.values(flow[1]).find(flowValue => flowValue === productKey)
  );
  if (productKeyWithFlow && productKeyWithFlow[0]) {
    return (productRTEProps[
      productKeyWithFlow[0] as K
    ] as unknown) as ProductProps<K>;
  }
  return (productRTEProps[productKey] as unknown) as ProductProps<K>;
}

/**
 * @returns All the product keys as an array
 */
export function getProductKeys(): Array<ProductKey> {
  const keys = Object.keys(productProps) as Array<ProductKey>;
  return keys;
}

/**
 * Gets the product key associated with the given slug. Used for finding
 * which product to preselect when visiting '/buy/:productKey'
 * @param slug The slug
 * @returns The associated prdoct key or undefined if not found
 */
export function getProductKeysFromSlug(slug: string): ProductKey | undefined {
  return getProductKeys().find(k =>
    productProps[k].slugs.find(s => s === slug)
  );
}

/**
 * Calculates the correct PapiPlan for a given product selection
 * and optionally a center. We pass a center in order to get an
 * adjusted plan based on virtual/non virtual. This function only gets
 * the default purchasable plan, it will not get an adjusted waitlist plan.
 * If no center is passed then we get back a default plan.
 * @param selection The product selection
 * @param allPlans The complete list of Dapi plans
 * @returns The plan if it can be found, otherwise undefined
 */
export function getPlanForProductSelection(
  selection: AnyProductSelection,
  allPlans: PapiPlan[],
  center?: PapiCenter | null
): PapiPlanExtended | undefined {
  const allPlantsSorted = allPlans.sort((a, b) =>
    a.joinCentersAllowed.length > b.joinCentersAllowed.length ? -1 : 1
  );
  switch (selection.productKey) {
    case PapiProductType.COMPLETE_CARE_Y1: {
      const productProps = getProductProps(selection.productKey);
      const productType =
        center && center.isVirtual && productProps
          ? productProps.virtualProductType
          : selection.productKey;
      const plan = allPlans.reduce(
        (prev, curr) =>
          curr.paymentRateInCents > 0 &&
          curr.productType === productType &&
          curr.billingPeriod === selection.params.billingPeriod &&
          curr.insuranceType ===
            (selection.params.insuranceType ?? PapiInsuranceType.CASH_PAY) &&
          curr.paymentRateInCents > (prev.paymentRateInCents ?? 0)
            ? curr
            : prev,
        {} as PapiPlan
      );
      return plan;
    }

    case PapiProductType.VIRTUAL_COMPLETE_CARE_Y1: {
      const productProps = getProductProps(selection.productKey);
      const productType =
        center && center.isVirtual && productProps
          ? productProps.virtualProductType
          : selection.productKey;
      const plan = allPlans.reduce(
        (prev, curr) =>
          curr.paymentRateInCents > 0 &&
          curr.productType === productType &&
          curr.billingPeriod === selection.params.billingPeriod &&
          curr.insuranceType ===
            (selection.params.insuranceType ?? PapiInsuranceType.CASH_PAY) &&
          curr.paymentRateInCents > (prev.paymentRateInCents ?? 0)
            ? curr
            : prev,
        {} as PapiPlan
      );
      return plan;
    }

    case PapiProductType.PEDIATRICS: {
      const plan = allPlantsSorted.reduce(
        (prev, curr) =>
          curr.paymentRateInCents > 0 &&
          curr.productType === selection.productKey &&
          curr.billingPeriod === selection.params.billingPeriod &&
          curr.paymentRateInCents > (prev.paymentRateInCents ?? 0)
            ? curr
            : prev,
        {} as PapiPlan
      );
      return plan;
    }

    // For the remaining product keys there is only one possible plan
    default: {
      return allPlans.reduce(
        (prev, curr) =>
          curr.paymentRateInCents > 0 &&
          curr.productType === PapiProductType.COMPLETE_CARE_Y1 &&
          curr.paymentRateInCents > (prev.paymentRateInCents ?? 0)
            ? curr
            : prev,
        {} as PapiPlan
      );
    }
  }
}

export function getAllPlanForProduct(
  productKey: string,
  allPlans: PapiPlan[],
  center?: PapiCenter | null
): PapiPlan[] {
  switch (productKey) {
    case PapiProductType.COMPLETE_CARE_Y1: {
      const productProps = getProductProps(productKey);
      const productType =
        center && center.isVirtual && productProps
          ? productProps.virtualProductType
          : productKey;
      const plan = allPlans.filter(
        plan => plan.paymentRateInCents > 0 && plan.productType === productType
      );
      return plan;
    }

    default: {
      return allPlans.filter(
        plan => plan.paymentRateInCents > 0 && plan.productType === productKey
      );
    }
  }
}

export function worthSavings(availablePlans: PapiPlan[]): boolean {
  if (!availablePlans[0] || !availablePlans[1]) {
    return false;
  }
  return (
    Math.abs(
      (availablePlans[0]?.totalPaymentInCents ?? 0) -
        (availablePlans[1]?.totalPaymentInCents ?? 0)
    ) /
      100 >
    10
  );
}
