import React from 'react';
import { ThemeProvider } from '@parsleyhealth/cilantro-ui';
import {
  Session,
  Screens,
  Auth,
  ValidatedCoupon,
  FirebaseProvider,
  GqlProvider
} from '../../state';
import SessionHydrator from './session-hydrator';

const JoinProvider: React.FC = props => {
  return (
    <FirebaseProvider>
      <Session.Provider>
        <GqlProvider>
          <Auth.Provider>
            <SessionHydrator>
              <ValidatedCoupon.Provider>
                <Screens.Provider>
                  <ThemeProvider>{props.children}</ThemeProvider>
                </Screens.Provider>
              </ValidatedCoupon.Provider>
            </SessionHydrator>
          </Auth.Provider>
        </GqlProvider>
      </Session.Provider>
    </FirebaseProvider>
  );
};

export default JoinProvider;
