import { useMemo } from 'react';
import { useCart, useUpdateCart } from './';
import { CartItemInput, PapiPlan, Selection } from '../types';
import useValidPlansByProduct from './use-valid-plans-by-product';

type TUseBillingTypeModifier = {
  alternatePlan: PapiPlan | undefined;
  currentPlan: PapiPlan;
  changePlan: () => void;
};

const useBillingTypeModifier = (
  { plan: currentPlan }: Selection,
  allPlans: PapiPlan[]
): TUseBillingTypeModifier => {
  const { cartItems } = useCart();
  const { editCartItem } = useUpdateCart();
  const {
    id: cartItemId,
    params: cartItemParams,
    productKey,
    slug
  } = cartItems[0];
  let { data: availablePlans } = useValidPlansByProduct(productKey);

  const alternatePlan = useMemo(() => {
    if (!('billingPeriod' in cartItemParams)) {
      return;
    }

    // Sort plans by most expensive to least expensive
    // to ensure the most expensive pland is found
    availablePlans = availablePlans?.sort(
      (a, b) => b.annualCostInCents - a.annualCostInCents
    );

    return availablePlans?.find(
      item =>
        item.billingPeriod !== cartItemParams.billingPeriod &&
        item.joinCentersAllowed?.length ===
          currentPlan?.joinCentersAllowed?.length &&
        item.insuranceType === currentPlan?.insuranceType
    );
  }, [allPlans, cartItemParams, productKey]); // eslint-disable-line

  const changePlan = (): void => {
    if (!alternatePlan) {
      return;
    }

    const itemInput: CartItemInput<any> = {
      productKey,
      productType: alternatePlan.productType,
      params: {
        billingPeriod: alternatePlan.billingPeriod,
        insuranceType: alternatePlan.insuranceType
      },
      planID: alternatePlan.id,
      planName: alternatePlan.displayName,
      purchaseID: null,
      isWaitlisted: false,
      slug
    };

    editCartItem(cartItemId, itemInput);
  };

  return {
    alternatePlan,
    currentPlan,
    changePlan
  };
};

export default useBillingTypeModifier;
