import { useState, useCallback } from 'react';
import { createContainer, useContainer } from 'unstated-next';
import { EntriesData } from '../types';

interface TUseEntries {
  entries: Partial<EntriesData>;
}

type SaveEntries = {
  (props: Partial<EntriesData>): Promise<Partial<EntriesData>>;
};
type ClearEntries = { (): Promise<void> };

interface TUseUpdateEntries {
  saveEntries: SaveEntries;
  clearEntries: ClearEntries;
}

function useEntriesContainer(
  initialState: Partial<EntriesData> = {}
): {
  entries: Partial<EntriesData>;
  saveEntries: SaveEntries;
  clearEntries: ClearEntries;
} {
  const [entries, setEntries] = useState<Partial<EntriesData>>(initialState);

  const saveEntries = useCallback(
    async (props: Partial<EntriesData>) => {
      const newEntries = { ...entries, ...props };
      try {
        setEntries(entries => ({ ...entries, ...props }));
        return newEntries;
      } catch (err) {
        return newEntries;
      }
    },
    [entries]
  );

  const clearEntries: ClearEntries = useCallback(async () => {
    setEntries({});
    return;
  }, []);

  return { entries, saveEntries, clearEntries };
}

export const EntriesContainer = createContainer(useEntriesContainer);

export function useEntries(): TUseEntries {
  const { entries } = useContainer(EntriesContainer);
  return { entries };
}

export function useUpdateEntries(): TUseUpdateEntries {
  const { saveEntries, clearEntries } = useContainer(EntriesContainer);
  return { saveEntries, clearEntries };
}
