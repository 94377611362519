import React, { useEffect, FC } from 'react';
import { Link } from '../atoms/link';
import { useNavigate, Loader } from '../../state';
import { JOIN_PARSLEY_URL } from '../../constants/external-links';
import { Carrot } from '@ph-bit/design.ui.icons.carrot';
import { Logo } from '../atoms/icons/logo';
import { navigate, useLocation } from '@reach/router';

export const Header: FC = () => {
  const { back, getScreenIndex, currentScreen } = useNavigate();
  const { startLoad, loading, loaderType } = Loader.useContainer();
  const location = useLocation();

  const showBackArrow =
    !(loading && loaderType === 'purchase') &&
    currentScreen &&
    getScreenIndex() !== 0 &&
    !currentScreen.hideBack;

  useEffect(() => {
    const handlePopstate = async (): Promise<any> => {
      if (!showBackArrow) return;
      navigate(location.pathname); // Re-navigate to the current location
      await startLoad();
      back();
    };

    // Attach event listeners when the component mounts
    window.addEventListener('popstate', handlePopstate);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [location, back, startLoad, showBackArrow]);

  return (
    <div className="ph-h-70 ph-py-0 ph-px-8 ph-bg-ivory md:ph-bg-transparent md:ph-px-16">
      <div className="ph-relative ph-h-full ph-flex ph-items-center ph-justify-center">
        {showBackArrow ? (
          <div
            className="ph-absolute ph-left-0"
            onClick={async () => {
              await startLoad();
              back();
            }}
          >
            <Carrot direction="left" size="m" />
          </div>
        ) : null}
        <Link href={JOIN_PARSLEY_URL}>
          <Logo className="ph-max-w-150 ph-text-dark-green" />
        </Link>
      </div>
    </div>
  );
};
