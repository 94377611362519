import React from 'react';
import Icon, { IconProps } from '../icon';

const X: React.FC<IconProps> = props => {
  return (
    <Icon height={9} width={9} ariaLabel="close icon" {...props}>
      <path
        d="M6.41421356,6.66133815e-14 L7.82842712,1.41421356 L5.328,3.914 L7.82842712,6.41421356 L6.41421356,7.82842712 L3.914,5.328 L1.41421356,7.82842712 L1.43085543e-12,6.41421356 L2.5,3.914 L1.43085543e-12,1.41421356 L1.41421356,6.70574707e-14 L3.914,2.5 L6.41421356,6.66133815e-14 Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default X;
