import { useCart, useEntries } from '../state';
import getAvailableCentersForProductSelection from '../lib/get-available-centers-for-product-selection';
import useAvailableCenters from '../api/use-available-centers';
import useAllPlans from '../state/use-all-plans';
import { FilteredQueryResult } from '../types';
import { getAvailabilities_getAvailabilities } from '../api/generated-types/getAvailabilities';

type TResult = {
  centersInStateOrInRange: getAvailabilities_getAvailabilities[];
  physicalCentersOutOfRange: getAvailabilities_getAvailabilities[];
};

const useCentersByLocation = (): FilteredQueryResult<TResult | undefined> => {
  const { cartItems } = useCart();
  const { entries } = useEntries();
  const availableCentersResult = useAvailableCenters(entries.addressPostalCode);
  const allPlansResult = useAllPlans();
  const loading = availableCentersResult.loading || allPlansResult.loading;
  const error = availableCentersResult.error || allPlansResult.error;

  const availableCenters = availableCentersResult.data;
  const allPlans = allPlansResult.data;

  if (!availableCenters || !allPlans || loading || cartItems.length === 0) {
    return {
      data: undefined,
      loading,
      error
    };
  }

  const availabilities = getAvailableCentersForProductSelection(
    cartItems[0],
    availableCenters.getAvailabilities,
    allPlans
  );

  // Virtual centers has been filtered out already by State
  const centersInStateOrInRange = availabilities.filter(a => {
    return a.center.isVirtual || a.isWithinRange;
  });

  const physicalCentersOutOfRange = availabilities.filter(
    a => !a.center.isVirtual && !a.isWithinRange
  );

  return {
    data: {
      centersInStateOrInRange,
      physicalCentersOutOfRange
    },
    loading,
    error
  };
};

export default useCentersByLocation;
