import { PapiCenter, FilteredQueryResult } from '../types';
import { useContainer } from 'unstated-next';
import Loader from './loader';
import { useCart, useUpdateEntries, useUpdateCart, useIsWaitlisted } from '.';
import useAllPlans from './use-all-plans';
import { getPlanForProductSelection } from '../lib/products';
import { identify, track } from '@parsleyhealth/cilantro-track';
import { useEntries } from './entries';
import { PapiPlanExtended } from './use-all-plans';

type TResult = {
  onSelectPlan?: () => Promise<void>;
  isWaitlisted?: boolean;
  isPlan: boolean;
};

const useSelectCenter = (
  center: PapiCenter,
  next?: () => void,
  planId?: string
): FilteredQueryResult<TResult | undefined> => {
  const { saveEntries } = useUpdateEntries();
  const { startLoad } = useContainer(Loader);
  const { entries } = useEntries();
  const { cartItems } = useCart();
  const { editCartItem } = useUpdateCart();
  const allPlansResult = useAllPlans();
  const getIsWaitlistedResult = useIsWaitlisted();
  const allPlans = allPlansResult.data;
  const getIsWaitlisted = getIsWaitlistedResult.data;
  const loading = allPlansResult.loading || getIsWaitlistedResult.loading;
  const error = allPlansResult.error || getIsWaitlistedResult.error;
  const comingFromPlanRoute = entries.comingFromPlanRoute;

  if (!allPlans || !getIsWaitlisted || loading) {
    return {
      data: undefined,
      loading,
      error
    };
  }

  let plan: PapiPlanExtended | undefined = undefined;
  if (comingFromPlanRoute) {
    plan = allPlans.find(plan => plan.id === planId);
  } else {
    plan = getPlanForProductSelection(cartItems[0], allPlans, center);
  }

  if (!plan) {
    return {
      data: {
        isPlan: false
      },
      loading,
      error
    };
  }

  const waitlistPlanId = getIsWaitlisted(center, plan);
  const isWaitlisted = Boolean(waitlistPlanId);

  const onSelectPlan = async (): Promise<void> => {
    try {
      if (next) await startLoad();
      await saveEntries({
        centerID: center.id,
        // TODO: are this fields required still?
        hubspotSelectedCenter: center.name,
        hubspotWaitlistSelected: isWaitlisted
      });
      // Update the cart item in case the product type and plan changed.
      // This would happen if the user selected a vitual center option
      // but had a physical product selection
      await editCartItem(cartItems[0].id, {
        productType: plan?.productType,
        planName: plan?.displayName,
        planID: waitlistPlanId ? waitlistPlanId : plan?.id,
        isWaitlisted: isWaitlisted
      });

      identify({
        center: center.name
      });

      track('Center or Anywhere Selected', {
        center: center.name,
        waitlist: isWaitlisted
      });

      if (next) next();
    } catch (err) {
      track('Join Flow Error', {
        product: plan?.productType,
        description: err as string
      });
      startLoad('error');
    }
  };

  return {
    data: {
      onSelectPlan,
      isWaitlisted,
      isPlan: true
    },
    loading,
    error
  };
};

export default useSelectCenter;
