/**
 * The screens map contains configuration for all possible
 * screens in the flow. Here you can add new screens
 * and the routes with automatically reflect the new configuration.
 *
 * You can also configure what user entries are required in order
 * to "complete" the screen. This is used to determine
 * the user's progress through the flow if they leave and return.
 */

import { Screens, ScreenKeys } from '../types';

export const screens: Screens = {
  welcome: {
    name: 'Welcome',
    pathname: '/welcome/',
    entryRequired: entries => {
      if (entries.purchaseID && entries.dapiPersonID) {
        return false;
      }
      return (
        entries.email === undefined || entries.addressPostalCode === undefined
      );
    },
    includeInProgress: () => true,
    componentName: 'Welcome'
  },
  location: {
    name: 'Location',
    pathname: '/location/',
    entryRequired: entries => {
      if (entries.purchaseID && entries.dapiPersonID) {
        return false;
      }
      return entries.centerID === undefined;
    },
    includeInProgress: (entries, pathname) => {
      if (entries && pathname) {
        if (entries.ignoreScreens?.length === 0) return true;
        if (entries.ignoreScreens?.includes(pathname)) return false;
      }

      return false;
    },
    componentName: 'SelectLocation'
  },
  insuranceRTE: {
    name: 'InsuranceRTE',
    pathname: '/insurance-rte/',
    entryRequired: () => {
      return false;
    },
    includeInProgress: (entries, pathname) => {
      if (entries && pathname) {
        if (entries.ignoreScreens?.length === 0) return true;
        if (entries.ignoreScreens?.includes(pathname)) return false;
      }
      return false;
    },
    componentName: 'InsuranceRTE'
  },
  insuranceNotEligible: {
    name: 'InsuranceNotEligible',
    pathname: '/insurance-not-eligible/',
    entryRequired: () => {
      return false;
    },
    includeInProgress: (entries, pathname) => {
      if (entries && pathname) {
        if (!entries.eligibilityStatus) return false;
        if (entries.ignoreScreens?.length === 0) return true;
        if (entries.ignoreScreens?.includes(pathname)) return false;
      }
      return false;
    },
    componentName: 'InsuranceNotEligible'
  },
  paymentPlan: {
    name: 'PaymentPlan',
    pathname: '/payment-plan/',
    entryRequired: () => {
      return false;
    },
    includeInProgress: () => true,
    componentName: 'PaymentPlan'
  },
  accountDetails: {
    name: 'AccountDetails',
    pathname: '/account-details/',
    entryRequired: entries => {
      return !entries.firstName || !entries.lastName || !entries.centerID;
    },
    includeInProgress: () => false,
    componentName: 'AccountDetails'
  },
  billingDetails: {
    name: 'BillingDetails',
    pathname: '/billing-details/',
    entryRequired: entries => {
      return !entries.stripeToken || !entries.purchaseID;
    },
    includeInProgress: () => false,
    componentName: 'BillingDetails'
  },
  checkout: {
    name: 'Checkout',
    pathname: '/checkout/',
    entryRequired: entries => {
      if (entries.purchaseID && entries.dapiPersonID) {
        return false;
      }
      return (
        entries.firstName === undefined ||
        entries.lastName === undefined ||
        entries.stripeToken === undefined ||
        entries.dateOfBirth === undefined ||
        entries.biologicalSex === undefined ||
        entries.password === undefined ||
        entries.stripeToken === undefined ||
        entries.stripeToken === null
      );
    },
    includeInProgress: () => true,
    componentName: 'Checkout'
  },
  review: {
    name: 'Review',
    pathname: '/review/',
    entryRequired: entries => {
      if (
        entries.purchaseID &&
        entries.dapiPersonID &&
        entries.paidLatestInvoice
      ) {
        return false;
      }
      return (
        entries.dapiPersonID === undefined ||
        entries.purchaseID === undefined ||
        entries.paidLatestInvoice === undefined
      );
    },
    includeInProgress: () => false,
    hideProgressBar: false,
    hideCounter: true,
    componentName: 'Review'
  },
  success: {
    name: 'Success',
    pathname: '/success/',
    entryRequired: () => {
      return true;
    },
    includeInProgress: () => false,
    hideBack: true,
    hideProgressBar: true,
    hideCounter: true,
    componentName: 'Success'
  }
};

export const screenKeys = Object.keys(screens) as ScreenKeys[];
