import React from 'react';
import clsx from 'clsx';
import { useProgress, useCounter, useNavigate } from '../../state';

import { CircleStep } from '../atoms/icons/circle-step';

const ProgressBar: React.FC = () => {
  const { currentScreen } = useNavigate();
  const progress = useProgress();
  const [completedScreen, screens] = useCounter();

  if (currentScreen === undefined) {
    return null;
  }

  const isHidden = currentScreen.hideProgressBar === true;
  return (
    <div
      className={clsx(
        'ph-w-full',
        'ph-h-1',
        'ph-bg-clover',
        'ph-relative',
        isHidden ? 'ph-opacity-0' : 'ph-opacity-100',
        isHidden
          ? 'ph-transition-opacity ph-duration-30 ph-ease-40'
          : 'ph-transition-opacity ph-duration-30 ph-ease-0'
      )}
    >
      <div
        className={clsx('ph-h-full', 'ph-bg-sage', 'ph-z-10')}
        style={{
          width: `${progress * 100}%`
        }}
      />
      {[...Array(screens + 1).keys()].map(screen => (
        <div
          key={screen.toString()}
          style={{
            left: `${
              screen / screens === 0 ? '-0.2' : (screen / screens) * 100
            }%`
          }}
          className={clsx(
            completedScreen >= screen
              ? 'ph--bottom-2 md:ph--bottom-3'
              : 'ph--bottom-5 md:ph--bottom-1.5',
            'ph-absolute',
            'ph-z-50'
          )}
        >
          <CircleStep
            className={clsx(
              completedScreen >= screen
                ? 'ph-text-sage ph-w-4 ph-h-4 ph md:ph-w-6 md:ph-h-6'
                : 'ph-text-clover ph-w-2 ph-h-2 md:ph-w-3 md:ph-h-3'
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
