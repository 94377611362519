import React from 'react';
import { Box, P, H3, Logo } from '@parsleyhealth/cilantro-ui';
import { OpenHelpScoutLink } from '../atoms/open-help-scout-link';
import { MAINTENANCE_MODE } from '../../constants/env';

const Maintenance: React.FC = props => {
  if (MAINTENANCE_MODE) {
    return (
      <div className="ph-w-screen ph-h-screen ph-p-xs flex ph-items-center ph-content-center ph-box-border">
        <div className="ph-text-center">
          <Box mb="s">
            <Logo />
          </Box>
          <Box mb="s">
            <H3>We are currently down for maintenance.</H3>
          </Box>
          <P>
            Please contact{' '}
            <OpenHelpScoutLink>Member Experience</OpenHelpScoutLink> to complete
            your sign up over the phone.
          </P>
        </div>
      </div>
    );
  }
  return <>{props.children}</>;
};

export default Maintenance;
