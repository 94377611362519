import React from 'react';
import { Link } from '@ph-bit/design.ui.buttons.link';

interface TermsLinkProps {
  href: string;
  label: string;
}

export const TermsLink: React.FC<TermsLinkProps> = ({ href, label }) => {
  return (
    <Link
      id="test-with-id"
      color="darkGreen"
      linkStyle="textOnly"
      href={href}
      size="s"
      target="_blank"
    >
      {label}
    </Link>
  );
};
