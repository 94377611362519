import { useState, useEffect } from 'react';
import { useContainer } from 'unstated-next';
import Loader from './loader';
import {
  useCart,
  useCentersByLocation,
  useNavigate,
  useEntries,
  useUpdateEntries,
  useUpdateCart
} from './';
import useWaitFor from './use-wait-for';
import { PapiProductType } from '../types/papi-global-types';
import { track } from '@parsleyhealth/cilantro-track';
import { ENABLE_IGNORE_SCREEN } from '../constants/env';
import { getPlanForProductSelection } from '../lib/products';
import useAllPlans, { PapiPlanExtended } from './use-all-plans';
import useGetMatchingLocationPlan from './use-get-matching-location-plan';

const useIgnoreScreen = (): void => {
  const { entries } = useEntries();
  const { saveEntries } = useUpdateEntries();
  const { startLoad } = useContainer(Loader);
  const [hasInitialized, setHasInitialized] = useState(false);

  const { cartItems } = useCart();
  const centersByLocation = useCentersByLocation();
  const { next, currentScreen } = useNavigate();
  const { editCartItem } = useUpdateCart();
  const allPlans = useAllPlans();
  const virtualAvailableCenters = centersByLocation?.data?.centersInStateOrInRange?.every(
    availability => availability.center.isVirtual
  );
  const currentPathname = currentScreen?.pathname;
  const comingFromPlanRoute = entries.comingFromPlanRoute;
  const matchingPlanId = useGetMatchingLocationPlan(
    cartItems[0].planID,
    cartItems[0].productType === PapiProductType.COMPLETE_CARE_Y1
  );

  useWaitFor([centersByLocation]);

  useEffect(() => {
    track('Checkout Step Viewed', {
      step: 2
    });
  }, []);

  useEffect(() => {
    const validateIgnoreScreen = async (): Promise<void> => {
      if (
        !currentPathname ||
        centersByLocation?.data?.centersInStateOrInRange?.length === 0 ||
        !ENABLE_IGNORE_SCREEN ||
        virtualAvailableCenters === undefined
      )
        return;
      setHasInitialized(true);
      if (virtualAvailableCenters) {
        // here we are assigning the virtual center available as a default
        // also we need to update the plan to a virtual using the current center
        await startLoad();
        const cartItem = cartItems[0];
        const center =
          centersByLocation?.data?.centersInStateOrInRange[0].center;

        let plan: PapiPlanExtended | undefined = undefined;
        if (comingFromPlanRoute) {
          plan = allPlans?.data?.find(plan => plan.id === matchingPlanId);
        } else {
          plan = getPlanForProductSelection(
            cartItem,
            allPlans?.data ?? [],
            center
          );
        }
        if (plan) {
          editCartItem(cartItem.id, {
            planID: plan.id,
            planName: plan.displayName
          });
        }
        await saveEntries({
          ignoreScreens: [currentPathname],
          centerID: center?.id
        });
        next();
      } else {
        await saveEntries({
          centerID: undefined
        });
      }
    };
    if (!hasInitialized) validateIgnoreScreen();
  }, [
    virtualAvailableCenters,
    next,
    saveEntries,
    startLoad,
    hasInitialized,
    currentPathname,
    centersByLocation.data,
    entries.ignoreScreens,
    centersByLocation,
    cartItems,
    allPlans.data,
    editCartItem,
    comingFromPlanRoute,
    matchingPlanId
  ]);
};

export default useIgnoreScreen;
