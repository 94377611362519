import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { fontSources } from '@parsleyhealth/cilantro-ui';
import useStripe from '../../state/use-stripe';

const StripeWrapper: React.FC = props => {
  const stripe = useStripe();

  if (stripe) {
    return (
      <StripeProvider stripe={stripe}>
        <Elements
          fonts={[
            {
              family: 'EuclidFlex',
              src: `url(${fontSources.euclidCircularBRegular}) format('woff')`,
              weight: '400',
              style: 'normal'
            }
          ]}
        >
          {props.children}
        </Elements>
      </StripeProvider>
    );
  } else {
    return null;
  }
};

export default StripeWrapper;
