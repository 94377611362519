import clsx from 'clsx';
import React from 'react';

export interface IconProps {
  ariaLabel?: string;
  className?: string;
  fill?: string;
  height?: number;
  stroke?: string;
  viewBox?: string;
  width?: number;
}

const Icon: React.FC<IconProps> = ({
  ariaLabel = 'icon',
  children,
  className,
  fill = 'currentColor',
  height,
  viewBox,
  width,
  ...rest
}) => (
  <svg
    aria-label={ariaLabel}
    className={clsx('ph-inline-block', className)}
    fill={fill}
    height={height}
    role="img"
    viewBox={viewBox || `0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
  </svg>
);

export default Icon;
