import useProducts from '../api/use-products';
import { ProductMetaData, FilteredQueryResult } from '../types';
import { PapiProductType } from '../types/papi-global-types';

const defaults: ProductMetaData = {
  body: [],
  color: '#333333',
  productName: '',
  planBenefits: null
};

const useProductMetaData = (
  productType: PapiProductType
): FilteredQueryResult<ProductMetaData> => {
  const { data, loading, error } = useProducts();
  let result = undefined;
  if (data) {
    const product = data.listRegisteredProducts.find(
      product => product.type === productType
    );
    if (product && product.sanityProduct) {
      result = {
        body: product.sanityProduct.body || defaults.body,
        color: product.sanityProduct.color || defaults.color,
        productName: product.sanityProduct.productName || defaults.productName,
        planBenefits:
          product.sanityProduct.planBenefits || defaults.planBenefits
      } as ProductMetaData;
    } else {
      result = defaults;
    }
  }
  return {
    data: result,
    loading,
    error
  };
};

export default useProductMetaData;
