import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Firebase } from './firebase';
import { UNIFIED_API_GRAPHQL_URI, VERSION } from '../constants/env';
import track from '@parsleyhealth/cilantro-track';

const cache = new InMemoryCache({
  addTypename: false
});

/**
 * Adds a bearer token header to the request
 * @returns void
 */
const authLink = (firebase: Firebase): ApolloLink =>
  setContext(async (_, { headers }) => {
    const token = await firebase.getSageIdToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

/**
 * Creates an Apollo client to talk to Product Api
 * @returns An Apollo client
 */
export const createClient = ({
  firebase
}: {
  firebase: Firebase;
}): ApolloClient<any> => {
  return new ApolloClient({
    name: 'Sprout',
    version: VERSION,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          console.error('[GraphQL error]', graphQLErrors);
          graphQLErrors.forEach(err => {
            track('Join Flow Error', {
              product: undefined,
              description: `[GraphQL error]: ${err.message}`
            });
            // TODO: implement DD logging
            console.error(
              `[GraphQL error on ${operation.operationName}]: ${err.message}`
            );
          });
        }
        if (networkError) {
          console.error('[Network error]', networkError);
        }
      }),
      authLink(firebase),
      new HttpLink({
        uri: UNIFIED_API_GRAPHQL_URI
      })
    ]),
    cache
  });
};
