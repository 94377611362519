import { ReactStripeElements } from 'react-stripe-elements';

type TCreateStripeToken = {
  (
    stripe: ReactStripeElements.StripeProps,
    firstName: string,
    addressPostalCode: string
  ): Promise<ReactStripeElements.TokenResponse>;
};

export default function useCreateStripeToken(): TCreateStripeToken {
  const createStripeToken: TCreateStripeToken = async (
    stripe,
    firstName,
    addressPostalCode
  ) => {
    const stripeResult = await stripe.createToken({
      name: firstName,
      address_zip: addressPostalCode
    });
    return stripeResult;
  };
  return createStripeToken;
}
