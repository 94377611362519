//import firebase from 'firebase'
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  FIREBASE_JOIN_API_KEY,
  FIREBASE_JOIN_AUTH_DOMAIN,
  FIREBASE_JOIN_PROJECT_ID,
  FIREBASE_SAGE_API_KEY,
  FIREBASE_SAGE_AUTH_DOMAIN,
  FIREBASE_SAGE_PROJECT_ID,
  FIRESTORE_HOST,
  FIRESTORE_USE_SLL
} from '../constants/env';

type UserCredentialResult = Promise<app.auth.UserCredential>;

const joinConfig = {
  apiKey: FIREBASE_JOIN_API_KEY,
  authDomain: FIREBASE_JOIN_AUTH_DOMAIN,
  projectId: FIREBASE_JOIN_PROJECT_ID
};

const sageAuthConfig = {
  apiKey: FIREBASE_SAGE_API_KEY,
  authDomain: FIREBASE_SAGE_AUTH_DOMAIN,
  projectId: FIREBASE_SAGE_PROJECT_ID
};

export class Firebase {
  sageAuth: firebase.auth.Auth;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  constructor() {
    const sageAuthApp = app.initializeApp(sageAuthConfig, 'auth');
    const joinApp = app.initializeApp(joinConfig, 'join');

    this.sageAuth = sageAuthApp.auth();
    this.auth = joinApp.auth();
    this.db = joinApp.firestore();

    if (FIRESTORE_HOST && FIRESTORE_USE_SLL) {
      this.db.settings({
        host: FIRESTORE_HOST,
        ssl: JSON.parse(FIRESTORE_USE_SLL)
      });
    }
  }

  /**
   * Sage Firebase methods
   */
  doSignIntoSageAnonymously = (): UserCredentialResult =>
    this.sageAuth.signInAnonymously();

  doSignIntoSageWithEmailAndPassword = ({
    email,
    password
  }: {
    email: string;
    password: string;
  }): UserCredentialResult =>
    this.sageAuth.signInWithEmailAndPassword(email, password);

  getSageIdToken = async (): Promise<string | null> => {
    if (this.sageAuth.currentUser) {
      return this.sageAuth.currentUser.getIdToken();
    }
    return null;
  };

  doSignIntoSageWithCustomToken = (token: string): UserCredentialResult =>
    this.sageAuth.signInWithCustomToken(token);

  doSignOutOfSage = (): Promise<void> => this.sageAuth.signOut();

  doSetPassword = (password: string): Promise<void> => {
    const currentUser = this.sageAuth.currentUser;
    if (currentUser === null) {
      throw new Error('Cannot set password if user is not logged in.');
    }
    return currentUser.updatePassword(password);
  };

  /**
   * Sprout Firebase methods
   */
  doSignInAnonymously = (): UserCredentialResult =>
    this.auth.signInAnonymously();

  doSignOut = (): Promise<void> => this.auth.signOut();

  checkUser = async (): Promise<app.User | null> => {
    return new Promise<app.User | null>((resolve, reject) => {
      try {
        this.auth.onAuthStateChanged(user => {
          resolve(user);
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  getFsUserRef = (): app.firestore.DocumentReference | null => {
    if (this.auth.currentUser) {
      return this.db.collection('users').doc(this.auth.currentUser.uid);
    }
    return null;
  };
}

export default Firebase;
