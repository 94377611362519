import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import gql from 'graphql-tag';
import { currentPapiPerson } from './generated-types/currentPapiPerson';

const CURRENT_PAPI_PERSON = gql`
  query currentPapiPerson {
    currentPapiPerson {
      id
      addressPostalCode
      addressState
      biologicalSex
      dateOfBirth
      email
      firstName
      genderIdentity
      lastName
      center {
        id
      }
      currentPlan {
        center {
          id
        }
        plan {
          id
          product {
            membershipType
          }
        }
      }
    }
  }
`;

export default function useCurrentPerson(): QueryResult<
  currentPapiPerson,
  Record<string, any>
> {
  return useQuery(CURRENT_PAPI_PERSON);
}
