import {
  REQUIRED_FIELD_ERROR,
  PASSWORD_MINIMUM_LENGTH_ERROR,
  PASSWORD_PWNED_ERROR
} from '../constants/user-messages';
import { MINIMUM_PASSWORD_LENGTH } from '../constants/password-requirements';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { useState } from 'react';

const VALIDATE_PASSWORD_SECURITY_QUERY = gql`
  query validatePasswordSecurity($password: String!) {
    validatePasswordSecurity(password: $password) {
      isSecure
      errorMessage
      errorCode
    }
  }
`;

export interface UseValidatePassword {
  validate: (value: string | undefined) => string | undefined;
  backendValidating: boolean;
  errorMessage: string | undefined;
  password: string | undefined;
}

const useValidatePassword = (): UseValidatePassword => {
  const client = useApolloClient();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [backendValidating, setBackendValidating] = useState<boolean>(false);
  const validate = (value: string | undefined): string | undefined => {
    setPassword(value);
    if (!value) {
      return REQUIRED_FIELD_ERROR;
    }

    if (value.length < MINIMUM_PASSWORD_LENGTH) {
      return PASSWORD_MINIMUM_LENGTH_ERROR;
    }

    setBackendValidating(true);
    client
      .query({
        query: VALIDATE_PASSWORD_SECURITY_QUERY,
        variables: {
          password: value
        },
        fetchPolicy: 'network-only'
      })
      .then(resp => {
        if (resp?.data?.validatePasswordSecurity.isSecure) {
          setErrorMessage(undefined);
          setBackendValidating(false);
        } else {
          setErrorMessage(PASSWORD_PWNED_ERROR);
        }
      });

    return undefined;
  };

  return { validate, errorMessage, backendValidating, password };
};

export default useValidatePassword;
