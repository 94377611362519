import { addSplitTestPrefix, randomBetween1and100, selectVariant } from '.';
import { Variant } from './select-variant';

type SplitTest = {
  key: string;
  active: boolean;
  variants?: [Variant];
};

// @TODO find a way to fetch split tests only once per session
// @TODO fetch split tests from Sanity
const splitTests: SplitTest[] = [
  {
    key: 'PROVIDER_MATCHING',
    active: true
  }
];

export const setupSplitTest = (
  splitTestKey: string,
  forcedValue?: string
): string | null => {
  // @TODO connect to Sanity
  const desiredSplitTest = splitTests.find(t => t.key === splitTestKey);

  // @TODO also check if split test is active. Requires to check Sanity first.
  // It would mean many requests to the CDN. Consider it or later.
  // Might be useful to create an endpoint that contains all split tests and their status.
  if (!desiredSplitTest) {
    console.error(`There is no split test with the key: ${splitTestKey}`);
    return null;
  }

  if (!desiredSplitTest.variants) {
    console.error(`There is no split test running right now`);
    return null;
  }

  const defaultVariant = desiredSplitTest.variants.find(t => t.isDefault);
  const ls = window.localStorage;

  if (!ls) {
    if (defaultVariant) {
      console.warn(
        `No access to local storage. Using default variant for the split test: ${splitTestKey}`
      );
    }

    console.error(
      `No access to local storage and there is no default variant for the split test: ${splitTestKey}.`
    );
    return null;
  }

  const prefixedSplitTestKey = addSplitTestPrefix(splitTestKey);

  if (forcedValue) {
    // @TODO check that the value is a possibility in the split test object
    ls.setItem(prefixedSplitTestKey, forcedValue);
    return forcedValue;
  }

  const lsSplitTest = ls.getItem(prefixedSplitTestKey);
  if (lsSplitTest) {
    return lsSplitTest;
  }

  // @TODO Add segment tracking
  const randomNumber = randomBetween1and100();
  const variant = selectVariant(desiredSplitTest.variants, randomNumber);
  ls.setItem(prefixedSplitTestKey, variant);
  return variant;
};
