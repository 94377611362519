import { useContainer } from 'unstated-next';
import { EntriesData } from '../types';
import {
  useEntries,
  useUpdateEntries,
  ValidatedCoupon,
  useCart
} from '../state/';
import useCreateAccount from './use-create-account';
import useRegisterCard from './use-register-card';
import useMakePurchase from './use-make-purchase';
import { makeNewPurchase } from './generated-types/makeNewPurchase';
import useIsWaitlisted from '../state/use-is-waitlisted';
import useSelections from '../state/use-selections';
import { getAnonymousUserId, identify } from '@parsleyhealth/cilantro-track';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

type TDoCheckout = { (): Promise<makeNewPurchase> };
const useDoCheckout = (): TDoCheckout => {
  const { entries } = useEntries();
  const cart = useCart();
  const cartItem = cart.cartItems[0];
  const { saveEntries } = useUpdateEntries();
  const [validatedCoupon] = useContainer(ValidatedCoupon);
  const createAccount = useCreateAccount();
  const registerCard = useRegisterCard();
  const makePurchase = useMakePurchase();
  const { data: getIsWaitlisted } = useIsWaitlisted();
  const selections = useSelections();

  return useCallback(async () => {
    if (!selections.data || !getIsWaitlisted) {
      throw new Error(
        'Cannot do checkout before plans and availabilties are loaded'
      );
    }

    const { center, plan } = selections.data;

    if (!center) {
      throw new Error('Cannot do checkout with no center selection');
    }

    if (!plan) {
      throw Error(`Cannot find Papi Plan for product selection.`);
    }

    let newEntries: Partial<EntriesData> = entries;
    // Create a user account if it doesn't exist
    if (entries.dapiPersonID === undefined) {
      newEntries = await createAccount({
        addressPostalCode: entries.addressPostalCode!,
        email: entries.email!,
        centerID: center.id,
        firstName: entries.firstName!,
        middleName: entries.middleName,
        lastName: entries.lastName!,
        dateOfBirth: entries.dateOfBirth!,
        biologicalSex: entries.biologicalSex!,
        password: entries.password!,
        addressState: entries.stateCode!,
        onboardingFlowSlug: `${cartItem?.slug}${
          entries.condition ? '-' + entries.condition : ''
        }`,
        analyticsID: getAnonymousUserId()!,
        assignmentOfBenefitsAcceptedAt: entries.assignmentOfBenefitsAcceptedAt,
        creditCardAuthAcceptedAt: entries.creditCardAuthAcceptedAt,
        membershipAgreementAcceptedAt: entries.membershipAgreementAcceptedAt,
        clinicalMembershipAgreementAcceptedAt:
          entries.clinicalMembershipAgreementAcceptedAt,
        termsOfUseAcceptedAt: entries.termsOfUseAcceptedAt,
        selfPaymentOfServicesTermsAcceptedAt:
          entries.selfPaymentOfServicesTermsAcceptedAt,
        privacyPolicyAcceptedAt: entries.privacyPolicyAcceptedAt,
        telehealthInformedConsentAcceptedAt:
          entries.telehealthInformedConsentAcceptedAt,
        hipaaAuthorizationAcceptedAt: entries.hipaaAuthorizationAcceptedAt,
        noticePrivacyPracticesAcceptedAt:
          entries.noticePrivacyPracticesAcceptedAt,
        telehealthAgreementAcceptedAt: entries.telehealthAgreementAcceptedAt
      });
    }

    if (!newEntries.dapiPersonID) {
      throw new Error('Error creating account.');
    }

    const { dapiPersonID, stripeToken } = newEntries;

    Cookies.set('dapiPersonID', dapiPersonID);

    try {
      if (!stripeToken) {
        throw Error('Unable to create check out, stripe token missing.');
      }
      await registerCard(stripeToken, dapiPersonID);

      const waitlistedPlanId = getIsWaitlisted(center, plan);

      const response = await makePurchase({
        planID: waitlistedPlanId ? waitlistedPlanId : plan.id,
        personID: dapiPersonID,
        payorID: dapiPersonID,
        centerID: center.id,
        addDefaultFollowOn: true,
        couponSlug: validatedCoupon?.slug,
        iterateOverFollowOns: false
      });

      if (!response) {
        throw new Error('Purchase unsuccessful.');
      }

      // TODO Implement empty cart
      await saveEntries({
        dapiPersonID,
        purchaseID: response.purchase.planPurchase.id,
        // TODO: are these fields required still?
        hubspotPurchaseSuccessful: true, //this field may not be necessary
        hubspotEnrolledPlanName: plan.displayName,
        hubspotEnrolledProductType: plan.productType,
        hubspotJoinWaitlistSuccessful: Boolean(waitlistedPlanId)
      });

      const identifyProperties = {
        dapi_p_id: dapiPersonID,
        email: entries.email!
      };
      identify({ ...identifyProperties });

      return response;
    } catch (err) {
      err.dapiPersonID = dapiPersonID;
      throw err;
    }
  }, [
    entries,
    selections,
    getIsWaitlisted,
    saveEntries,
    createAccount,
    makePurchase,
    validatedCoupon,
    cartItem,
    registerCard
  ]);
};

export default useDoCheckout;
