import React from 'react';
import clsx from 'clsx';

const P: React.FC<React.HTMLProps<HTMLParagraphElement>> = ({
  children,
  className,
  ...rest
}) => (
  <p
    className={clsx('ph-mb-2 ph-text-base ph-text-dark-green', className)}
    {...rest}
  >
    {children}
  </p>
);

export default P;
