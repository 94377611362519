import {
  PapiSex,
  PapiProductType,
  PapiBillingPeriod,
  PapiInsuranceType
} from './papi-global-types';
import {
  listRegisteredProducts_listRegisteredProducts_plans,
  listRegisteredProducts_listRegisteredProducts_agreements
} from '../api/generated-types/listRegisteredProducts';
import {
  getAvailabilities_getAvailabilities_center,
  getAvailabilities_getAvailabilities
} from '../api/generated-types/getAvailabilities';

//==============================================================
// Enums
//==============================================================

/**
 * Used for coupon display states
 */
export enum CouponDisplayStates {
  INITIAL = 0,
  VALIDATE = 1
}

export enum PapiCouponType {
  FIXED = 'amountOffCents',
  PERCENTAGE = 'percentOff'
}

export enum CCErrors {
  failedToGetToken = 'Failed to get token.'
}

export enum InsurancePayer {
  AETNA_NY = 'Aetna NY',
  AETNA_CA = 'Aetna CA',
  BSC = 'Blue Shield of California',
  OTHER = 'Other'
}

export enum UrlScreens {
  completeCare = 'complete-care',
  pediatrics = 'pediatrics'
}

export enum CHECKOUT_FLOW {
  ENHANCED_BILLING = 'enhanced-billing',
  DEFAULT = 'default'
}

//==============================================================
// Query params
//==============================================================

export interface QueryParams {
  email?: string;
  firstName?: string;
  billing_period?: PapiBillingPeriod;
  biologicalSex?: PapiSex;
  birthDate?: string;
  coupon?: string;
  token?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  /** Split Test */
  st?: string;
  /** Split Test Variant */
  stv?: string;
  variant?: string;
  /** Condition Specific */
  condition?: string;
  /** Insurance type */
  type?: string;
  payer?: string;
  flow?: string;
}

//==============================================================
// Lead capture form parameters/traits
//==============================================================

export interface LeadCaptureParams {
  email?: string;
  sproutZip?: string;
  sproutState?: string;
  sproutSelectedProductType?: string;
  sproutSelectedCenter?: string;
  sproutPaymentDetailsEntered?: boolean;
  sproutPurchaseSuccessful?: boolean;
  sproutWaitlistSelected?: boolean;
  sproutJoinWaitlistSuccessful?: boolean;
  sproutEnrolledPlanName?: string;
  sproutEnrolledProductType?: string;
  sproutCouponCode?: string;
  sproutNotifyMe?: boolean;
}

//==============================================================
// Products
//==============================================================

/**
 * ProductParams defines the set of parameters associated with
 * every possible product selection. The interface below
 * is used as a map to describe these associations.
 */
export interface ProductParams {
  [PapiProductType.COMPLETE_CARE_Y1]: {
    billingPeriod: PapiBillingPeriod | undefined;
    insuranceType?: PapiInsuranceType | string | null;
    checkoutFlow?: CHECKOUT_FLOW | null;
  };
  [PapiProductType.VIRTUAL_COMPLETE_CARE_Y1]: {
    billingPeriod: PapiBillingPeriod | undefined;
    insuranceType?: PapiInsuranceType | string | null;
    checkoutFlow?: CHECKOUT_FLOW | null;
  };
  [PapiProductType.PEDIATRICS]: {
    billingPeriod: PapiBillingPeriod | undefined;
    insuranceType?: PapiInsuranceType | string | null;
    checkoutFlow?: CHECKOUT_FLOW | null;
  };
}

/**
 * ProductKeys are the complete list of product options
 * the user sees when she is selecting a plan. It mostly
 * resembles the DAPI productType enum, but we keep them
 * independent for greater flexibility in the front end.
 */
export type ProductKey = keyof ProductParams;

export interface ProductPropsMeta {
  title: string;
  description: string;
  imageUrl: string;
  color: string;
}

/**
 * Any properties we want to associate with a ProductKey
 */
export interface ProductProps<K extends ProductKey> {
  slugs: UrlScreens[];
  productType: K;
  virtualProductType?: PapiProductType;
  initialParams: ProductParams[K];
  /**
   * `Flows` array with the possibles flows which this product
   * could display
   * Each `Flow` object represents the sequence of screens
   * the user goes through when this product is in their cart.
   * This allows us to dybamically change the flow based on the
   * users selection.
   */
  flows: { slug: string; flow: Flow }[];
}

/**
 * Type for the productProps map in constants.
 */
export type ProductPropsMap = { [K in ProductKey]: ProductProps<K> };

/**
 * Product meta data, which comes from Sanity
 */
export interface PlanBenefit {
  title: string;
  description: string;
}

export interface ProductMetaData {
  color: string;
  productName: string;
  body: any;
  planBenefits: Array<PlanBenefit> | null;
}

/**
 * A product selection is represented by a chosen product key
 * and associated parameters.
 */
export interface ProductSelection<K extends ProductKey> {
  productKey: K;
  params: ProductParams[K];
}

export type DistributeProductSelection<K> = K extends ProductKey
  ? ProductSelection<K>
  : never;

export type AnyProductSelection = DistributeProductSelection<ProductKey>;

//==============================================================
// Cart
//==============================================================

export interface CartItemInput<K extends ProductKey>
  extends ProductSelection<K> {
  purchaseID: string | null;
  planID: string;
  planName: string;
  productType: PapiProductType;
  isWaitlisted: boolean;
  slug: string;
}

export interface CartItem<K extends ProductKey> extends CartItemInput<K> {
  id: string;
}

/**
 * Allows us to create a union type of the possible CartItem values
 */
export type DistributeCartItems<U> = U extends ProductKey ? CartItem<U> : never;

export type AnyCartItem = DistributeCartItems<ProductKey>;

export type Cart = Array<AnyCartItem>;

//==============================================================
// Centers
//==============================================================

/**
 * A structure to represent a plan that is available at a center
 */
export interface CenterAvailability {
  center: PapiCenter;
  plan: PapiPlan | null;
}

/**
 * A structure to represent a Payer
 */
export interface Payer {
  id: string;
  nationalPayerId: string;
  payerName: string;
  accepted: boolean;
}

//==============================================================
// Form data
//==============================================================

export interface WelcomeFormData {
  email: string;
  addressPostalCode: string;
  stateCode?: string;
  insurancePayer?: string;
}

export interface ZipFormData {
  addressPostalCode: string;
  stateCode?: string;
}

export interface EmailFormData {
  email: string;
  privacyPolicyAcceptedAt: string;
  termsOfUseAcceptedAt: string;
}

export interface LocationFormData {
  centerID: string;
}

export interface PlanFormData {
  planID: string;
}

export interface TermsFormData {
  selfPaymentOfServicesTermsAcceptedAt?: string | null; // TODO: confirm what this is for
  membershipAgreementAcceptedAt?: string | null; // IN_NETWORK only
  assignmentOfBenefitsAcceptedAt?: string | null; // IN_NETWORK only
  creditCardAuthAcceptedAt?: string | null; // IN_NETWORK only
  clinicalMembershipAgreementAcceptedAt?: string | null; // CASH_PAY only
  termsOfUseAcceptedAt?: string | null;
  privacyPolicyAcceptedAt?: string | null;
  telehealthInformedConsentAcceptedAt?: string | null;
  hipaaAuthorizationAcceptedAt?: string | null;
  noticePrivacyPracticesAcceptedAt?: string | null;
  telehealthAgreementAcceptedAt?: string | null;
}

export interface AccountFormData extends TermsFormData {
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  biologicalSex: PapiSex;
  password: string;
  // Maybe email as we have a variant that includes in email
  // in the form and another that doesn't
  email?: string;
  areasOfConcern?: string[];
}

export interface InsuranceFormData {
  insurancePayer: InsurancePayer;
  noInsurance: boolean;
  notListedInsurance: string;
}

export interface EligibilityFormData {
  payer: Payer;
  memberId: string;
  firstName?: string;
  lastName: string;
  dateOfBirth?: string;
  eligibilityStatus?: string;
}

export interface HubspotData {
  hubspotSelectedCenter: string;
  hubspotWaitlistSelected: boolean;
  hubspotPurchaseSuccessful: boolean;
  hubspotEnrolledPlanName: string;
  hubspotEnrolledProductType: string;
  hubspotJoinWaitlistSuccessful: boolean;
  hubspotNotifyMe: boolean;
}
export interface TransitionData {
  loaderAnimated?: boolean | null;
  singleProvider?: boolean | null | string;
  virtualAvailability?: boolean | null;
  continue?: boolean | null;
}

export type CreateAccountData = WelcomeFormData &
  LocationFormData &
  AccountFormData &
  TermsFormData & {
    addressState: string;
    assignedDoctorID?: string;
    onboardingFlowSlug?: string;
    analyticsID: string;
  };

//==============================================================
// Entries data
//==============================================================

/**
 * The complete set of properties that gets stored
 * in the Firestore user record. This does not include cart
 * data because that is stored in it's own collection.
 *
 * We persist all form data plus tracking identifiers
 */
export type EntriesData = WelcomeFormData &
  LocationFormData &
  AccountFormData &
  HubspotData &
  TransitionData &
  EligibilityFormData & {
    stripeToken?: stripe.Token | null;
    ignoreScreens?: string[];
    purchaseID: string | null;
    dapiPersonID: string | null;
    lastUsage: string;
    utmCampaign: string;
    utmContent: string;
    utmMedium: string;
    utmSource: string;
    couponCode: string | null;
    providerId?: any;
    condition?: string;
    insuranceType?: PapiInsuranceType;
    insurancePayer?: string;
    comingFromPlanRoute?: boolean;
    paidLatestInvoice?: string;
  };

//==============================================================
// Screens
//==============================================================

/**
 * A screen defines the parameters of each step in the user experience.
 */
export interface Screen {
  name: string;
  /**
   * A url pathname (e.g. '/example') to attach to the screen. The user
   * can return to this screen by visiting the url.
   */
  pathname: string;
  /**
   * A function you define to tell Sprout what state is needed
   * to be able to consider this screen 'complete'. You are
   * given the current entries and cart items state, and you should
   * return true if there is still more user input needed, false
   * otherwise. This is used to determine where the user is up
   * to in the flow.
   * @param entries The current entries
   * @param cartItems The current cart items
   */
  entryRequired(entries: Partial<EntriesData>, cartItems: Cart): boolean;
  /**
   * A function witch returns a flag on whether to include the completion of this screen in calculating
   * the progress bar percentage. If false, completing this screen does not advance
   * the progress bar.
   * @param entries The current entries
   * @param pathname The current url pathname of the screen
   */
  includeInProgress(entries?: Partial<EntriesData>, pathname?: string): boolean;
  /**
   * A flag on whether to show a back button on this screen.
   */
  hideBack?: boolean;
  /**
   * A flag on whether to show the progress bar on this screen.
   */
  hideProgressBar?: boolean;
  /**
   * A flag on whether to show the counter on this screen.
   */
  hideCounter?: boolean;
  /**
   * The name of the export React Component to be rendered under this
   * route. See `screen-routes.tsx` to find the names.
   */
  componentName: string;
  /**
   * The name of the export React Component to be rendered as header in this
   * screen. See `headers/index.tsx` to find the components.
   */
  templateName?: string;
}

export type ScreenKeys =
  | 'welcome'
  | 'location'
  | 'insuranceRTE'
  | 'insuranceNotEligible'
  | 'paymentPlan'
  | 'checkout'
  | 'review'
  | 'success'
  | 'accountDetails'
  | 'billingDetails';

/**
 * A configuration object to declare all the screen types
 * and define parameters.
 */
export type Screens = { [k in ScreenKeys]: Screen };

/**
 * A flow is a sequence of screens, and can be consumed in the app
 * using a ScreenManager.
 */
export type Flow = Screen[];

//==============================================================
// Selections
//==============================================================

/**
 * The pair of Plan and Center selected by the user
 */
export interface Selection {
  plan: PapiPlan;
  center: PapiCenter;
}

//==============================================================
// Graphql
//==============================================================

export interface FilteredQueryResult<TData> {
  data: TData | undefined;
  loading: boolean;
  error?: any;
}

//==============================================================
// Autogen Graphql aliases
//==============================================================

export type PapiPlan = listRegisteredProducts_listRegisteredProducts_plans & {
  productType: PapiProductType;
};
export type PapiCenter = getAvailabilities_getAvailabilities_center;
export type PapiAgreement = listRegisteredProducts_listRegisteredProducts_agreements;
export type PapiAvailableCenters = getAvailabilities_getAvailabilities[];
