import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  deletePapiPerson,
  deletePapiPersonVariables
} from './generated-types/deletePapiPerson';
import { useUpdateEntries } from '../state';
import { useFirebase } from '../state/firebase';

type TDeleteAccount = {
  (id: string): Promise<void>;
};

const DELETE_PAPI_PERSON = gql`
  mutation deletePapiPerson($input: ID!) {
    person: deletePapiPerson(personID: $input) {
      personResponse
    }
  }
`;

export default function useDeleteAccount(): TDeleteAccount {
  const [deletePapiPersonMutation] = useMutation<
    deletePapiPerson,
    deletePapiPersonVariables
  >(DELETE_PAPI_PERSON);
  const firebase = useFirebase();
  const { saveEntries } = useUpdateEntries();
  const deleteAccount: TDeleteAccount = async (id: string) => {
    try {
      if (id) {
        const result = await deletePapiPersonMutation({
          variables: {
            input: id
          }
        });

        if (result.errors) {
          throw result.errors;
        }
        // Sign out the user immediately
        if (result.data) {
          await firebase.doSignOut();

          // Remove the user id in the store
          await saveEntries({
            dapiPersonID: undefined
          });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  return deleteAccount;
}
