import React, { useEffect } from 'react';

import '../styles/index.css';

const withStyles = (Component: React.FC | React.ComponentType) => ({
  ...props
}) => {
  useEffect(() => {
    document.body.classList.add('ph-antialiased');
    document.body.classList.add('ph-text-dark-green');
    return () => {
      document.body.classList.add('ph-antialiased');
      document.body.classList.remove('ph-text-dark-green');
    };
  }, []);
  return <Component {...props} />;
};

export default withStyles;
