import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { CreateAccountData, EntriesData } from '../types';
import { PapiCreatePersonInput } from '../types/papi-global-types';
import {
  createPapiPerson,
  createPapiPersonVariables
} from './generated-types/createPapiPerson';
import { useUpdateEntries } from '../state';
import useRecaptcha from '../state/use-recaptcha';
import { useFirebase } from '../state/firebase';

type TCreateAccount = {
  (data: CreateAccountData): Promise<Partial<EntriesData>>;
};

const CREATE_PAPI_PERSON = gql`
  mutation createPapiPerson($input: PapiCreatePersonInput!) {
    person: createPapiPerson(input: $input) {
      id
      customToken: firebaseCustomRegistrationToken
    }
  }
`;

export default function useCreateAccount(): TCreateAccount {
  const [createPapiPersonMutation] = useMutation<
    createPapiPerson,
    createPapiPersonVariables
  >(CREATE_PAPI_PERSON);
  const firebase = useFirebase();
  const { saveEntries } = useUpdateEntries();
  const getToken = useRecaptcha();
  const createAccount: TCreateAccount = async (data: CreateAccountData) => {
    try {
      if (!getToken) {
        throw new Error('Cannot use recpatcha before initialization.');
      }
      const token = await getToken();
      const {
        email,
        firstName,
        lastName,
        dateOfBirth,
        biologicalSex,
        centerID,
        addressPostalCode,
        addressState,
        password,
        assignedDoctorID,
        onboardingFlowSlug,
        analyticsID,
        termsOfUseAcceptedAt,
        membershipAgreementAcceptedAt,
        selfPaymentOfServicesTermsAcceptedAt,
        assignmentOfBenefitsAcceptedAt,
        creditCardAuthAcceptedAt,
        clinicalMembershipAgreementAcceptedAt,
        privacyPolicyAcceptedAt,
        telehealthInformedConsentAcceptedAt,
        hipaaAuthorizationAcceptedAt,
        noticePrivacyPracticesAcceptedAt,
        telehealthAgreementAcceptedAt
      } = data;

      const input: PapiCreatePersonInput = {
        sendPasswordReset: false,
        recaptcha: {
          token,
          ipAddress: 'localhost'
        },
        email,
        firstName,
        lastName,
        dateOfBirth,
        biologicalSex,
        centerID,
        addressPostalCode,
        addressState,
        assignedDoctorID,
        onboardingFlowSlug,
        analyticsID,
        termsOfUseAcceptedAt,
        membershipAgreementAcceptedAt,
        selfPaymentOfServicesTermsAcceptedAt,
        assignmentOfBenefitsAcceptedAt,
        creditCardAuthAcceptedAt,
        clinicalMembershipAgreementAcceptedAt,
        privacyPolicyAcceptedAt,
        telehealthInformedConsentAcceptedAt,
        hipaaAuthorizationAcceptedAt,
        noticePrivacyPracticesAcceptedAt,
        telehealthAgreementAcceptedAt
      };

      const result = await createPapiPersonMutation({
        variables: {
          input
        }
      });

      if (result.errors) {
        throw result.errors;
      }
      // Sign in the user immediately using custom token
      if (result.data && result!.data!.person!.customToken) {
        await firebase.doSignIntoSageWithCustomToken(
          result.data.person.customToken
        );

        // The user is signed in so we can set their password
        await firebase.doSetPassword(password);

        // Save the user id in the store
        return await saveEntries({
          dapiPersonID: result.data.person.id
        });
      }
      throw new Error('Unable to sign in after account creation.');
    } catch (err) {
      throw err;
    }
  };

  return createAccount;
}
