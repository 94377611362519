import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { listRegisteredProducts } from './generated-types/listRegisteredProducts';
import gql from 'graphql-tag';

export const LIST_REGISTERED_PRODUCTS = gql`
  query listRegisteredProducts {
    listRegisteredProducts {
      type
      sanityProduct {
        color
        productName
        body
        planBenefits {
          title
          description
        }
        prices {
          amount
          billingPeriod
          promoPrice
          promoMessage
        }
        isUsingPromoPrice
      }
      plans {
        id
        displayName
        description
        paymentRateInCents
        totalPaymentInCents
        annualCostInCents
        commitmentLength
        numberOfBillingCycles
        billingPeriod
        minPersonAgeYears
        maxPersonAgeYears
        defaultFollowOnPlanID
        joinCentersAllowed {
          id
        }
        insuranceType
      }
      agreements {
        title
        url
        type
      }
    }
  }
`;

export default function useProducts(): QueryResult<
  listRegisteredProducts,
  Record<string, any>
> {
  return useQuery(LIST_REGISTERED_PRODUCTS);
}
