import { useState, useEffect } from 'react';
import { STRIPE_KEY } from '../constants/env';

function useStripe(): stripe.Stripe | null {
  const [stripe, setStripe] = useState<stripe.Stripe | null>(null);
  useEffect(() => {
    if (window.Stripe !== undefined) {
      setStripe(window.Stripe(STRIPE_KEY));
    } else {
      if (window.document !== null) {
        const stripeElem = window.document.querySelector('#stripe-js');
        if (stripeElem) {
          stripeElem.addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            setStripe(window.Stripe(STRIPE_KEY));
          });
        }
      }
    }
  }, []);

  return stripe;
}

export default useStripe;
