import React from 'react';
import Icon, { IconProps } from '../icon';

const InputCheck: React.FC<IconProps> = props => {
  return (
    <Icon height={9} width={11} ariaLabel="check icon" {...props}>
      <g transform="translate(1, 0)">
        <polygon points="7.29307446 -0.707287977 8.70692554 0.707287977 2.99695303 6.41433451 -0.707408954 2.70680448 0.707408954 1.29319552 2.997 3.585" />
      </g>
    </Icon>
  );
};

export default InputCheck;
