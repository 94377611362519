import React from 'react';

const Radar: React.FC = () => (
  <span className="ph-relative ph-top-0 ph-right-0 ph-inline-flex ph-w-2 ph-h-2 ph--mt-1 ph--mr-1">
    <span className="ph-absolute ph-inline-flex ph-w-full ph-h-full ph-rounded-full ph-opacity-75 ph-bg-dark-green ph-animate-ping"></span>
    <span className="ph-relative ph-inline-flex ph-w-3 ph-h-2 ph-rounded-full ph-bg-sage"></span>
  </span>
);

export default Radar;
