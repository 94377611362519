import { useMemo } from 'react';
import useProducts from '../api/use-products';
import {
  listRegisteredProducts_listRegisteredProducts_sanityProduct as SanityProduct,
  listRegisteredProducts_listRegisteredProducts_plans as Plan
} from '../api/generated-types/listRegisteredProducts';
import { PapiPlan, FilteredQueryResult } from '../types';

export interface PapiPlanExtended extends PapiPlan {
  promoPrice?: number;
  promoMessage?: any;
  isUsingPromoPrice?: boolean;
}

const getPromoInfo: any = (sanityInfo?: SanityProduct, plan?: Plan) => {
  let promoPrice;
  let promoMessage;
  const isUsingPromoPrice = sanityInfo?.isUsingPromoPrice;
  const price = sanityInfo?.prices?.find(
    p => p?.billingPeriod === plan?.billingPeriod
  );

  if (sanityInfo && plan && price && isUsingPromoPrice) {
    promoPrice = price?.promoPrice;
    promoMessage = price.promoMessage;
  }

  return {
    promoPrice,
    promoMessage,
    isUsingPromoPrice
  };
};

const useAllPlans = (): FilteredQueryResult<PapiPlanExtended[] | null> => {
  const listProducts = useProducts();
  const allPlans = useMemo(() => {
    if (!listProducts.data) {
      return null;
    }
    return listProducts.data.listRegisteredProducts.reduce(
      (acc: PapiPlan[], current) => {
        const plansForProductType = current.plans
          .filter(plan => !plan.displayName.includes('Follow On'))
          .map(plan => {
            const promoInfo = getPromoInfo(current.sanityProduct, plan);
            return {
              ...plan,
              ...promoInfo,
              productType: current.type
            };
          });
        return acc.concat(plansForProductType);
      },
      []
    );
  }, [listProducts.data]);

  return {
    data: allPlans,
    error: listProducts.error,
    loading: listProducts.loading
  };
};

export default useAllPlans;
