export const VERSION = process.env.REACT_APP_VERSION;
export const NAME = process.env.REACT_APP_NAME;
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;

export const FIREBASE_JOIN_API_KEY =
  process.env.REACT_APP_FIREBASE_JOIN_API_KEY;
export const FIREBASE_JOIN_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_JOIN_AUTH_DOMAIN;
export const FIREBASE_JOIN_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_JOIN_PROJECT_ID;
export const FIREBASE_SAGE_API_KEY =
  process.env.REACT_APP_FIREBASE_SAGE_API_KEY;
export const FIREBASE_SAGE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_SAGE_AUTH_DOMAIN;
export const FIREBASE_SAGE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_SAGE_PROJECT_ID;
export const FIREBASE_FUNCTION_URL =
  process.env.REACT_APP_FIREBASE_FUNCTION_URL;
export const FIRESTORE_HOST = process.env.REACT_APP_FIRESTORE_HOST;
export const FIRESTORE_USE_SLL = process.env.REACT_APP_FIRESTORE_USE_SLL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const UNIFIED_API_URL = process.env.REACT_APP_UNIFIED_API_URL;
export const UNIFIED_API_GRAPHQL_URI = `${UNIFIED_API_URL}/graphql`;
export const SANITY_GRAPHQL_URI = process.env.REACT_APP_SANITY_GRAPHQL_URI;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
export const HUBSPOT_JOIN_FORM_ID = process.env.REACT_APP_HUBSPOT_JOIN_FORM_ID;
export const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;

export const REACT_APP_HELPSCOUT_BEACON_ID =
  process.env.REACT_APP_HELPSCOUT_BEACON_ID;

export const ENABLE_IGNORE_SCREEN = process.env.REACT_APP_ENABLE_IGNORE_SCREEN;
export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE;
