import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  useCart,
  useUpdateCart,
  useUpdateEntries,
  useNavigate,
  useFirebase
} from '../../state';
import usePlan from '../../api/use-get-plan';
import { ProductKey, QueryParams } from '../../types';
import queryString from 'query-string';
import { PapiInsuranceType, PapiSex } from '../../types/papi-global-types';
import { useCurrentPerson } from '../../api';

const Plan: React.FC<RouteComponentProps<{ planId: string }>> = props => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const { planId } = props;
  const plan = usePlan(planId);
  const { cartItems } = useCart();
  const { addToCart, emptyCart } = useUpdateCart();
  const { saveEntries, clearEntries } = useUpdateEntries();
  const { returnToLatest } = useNavigate();
  const firebase = useFirebase();
  const currentPersonResult = useCurrentPerson();
  const currentPerson = currentPersonResult.data?.currentPapiPerson;
  const params: QueryParams = queryString.parse(window.location.search);

  // In the case of users coming from the readiness quiz from Insights we send
  //(email, firstName, biologicalSex, dateOfBirth) in order to prepulate data to the form form them
  if (
    params.email ||
    params.firstName ||
    params.biologicalSex ||
    params.birthDate
  ) {
    saveEntries({
      email: params.email,
      firstName: params.firstName,
      biologicalSex: params.biologicalSex,
      dateOfBirth: params.birthDate
    });
  }

  // we need to wait for the new value for "returnToLatest"
  useEffect(() => {
    if (hasInitialized && cartItems.length > 0) {
      returnToLatest();
    }
  }, [cartItems, hasInitialized, returnToLatest]);

  useEffect(() => {
    const initBuy = async (): Promise<void> => {
      setHasInitialized(true);

      if (!plan.data) {
        throw Error('Plan not found in /plan');
      }

      const product = plan.data.plan?.product;
      const productKey = (product?.type as unknown) as ProductKey;
      if (productKey === undefined) {
        returnToLatest();
        return;
      }

      //add product to cart
      if (productKey !== undefined) {
        const planId = props.planId || '';
        const planName = plan.data.plan?.displayName || '';
        const insuranceType = plan.data.plan?.insuranceType || null;
        const productBillingPeriod = plan.data.plan?.billingPeriod;

        const params = { billingPeriod: productBillingPeriod };

        const queryParams: QueryParams = queryString.parse(
          window.location.search
        );
        let newEntries = {};

        // April 2021 split test. Grabbing variant and storing it in local storage so we can use it later
        if (queryParams.variant) {
          window.localStorage.setItem('SplitTestVariant', queryParams.variant);
        }

        if (queryParams.coupon) {
          newEntries = { ...newEntries, ...{ couponCode: queryParams.coupon } };
        }

        if (queryParams.type) {
          newEntries = {
            ...newEntries,
            ...{
              insuranceType:
                insuranceType === PapiInsuranceType.IN_NETWORK
                  ? PapiInsuranceType.IN_NETWORK
                  : PapiInsuranceType.CASH_PAY
            }
          };
        }

        if (queryParams.utm_campaign) {
          newEntries = {
            ...newEntries,
            ...{
              utmCampaign: queryParams.utm_campaign,
              utmContent: queryParams.utm_content,
              utmMedium: queryParams.utm_medium,
              utmSource: queryParams.utm_source
            }
          };
        }

        if (queryParams.condition) {
          newEntries = {
            ...newEntries,
            condition: queryParams.condition
          };
        }

        if (firebase.sageAuth && currentPerson) {
          newEntries = {
            ...newEntries,
            email: currentPerson.email,
            stateCode: currentPerson.addressState || undefined,
            firstName: currentPerson.firstName,
            lastName: currentPerson.lastName,
            dateOfBirth: currentPerson.dateOfBirth || undefined,
            centerID: currentPerson.center?.id,
            biologicalSex: currentPerson.biologicalSex as PapiSex,
            addressPostalCode: currentPerson.addressPostalCode || undefined,
            dapiPersonID: currentPerson.id,
            continue: true
          };
        }

        newEntries = {
          ...newEntries,
          comingFromPlanRoute: true
        };
        saveEntries(newEntries);
        await addToCart({
          params,
          productKey,
          productType: productKey,
          planID: planId,
          planName,
          purchaseID: null,
          isWaitlisted: false,
          slug: 'complete-care' //TODO: retrive slug value from sanity
        });
      }
    };
    if (
      !hasInitialized &&
      planId &&
      plan.data?.plan &&
      cartItems.length === 0
    ) {
      initBuy();
    }
  }, [
    addToCart,
    hasInitialized,
    cartItems,
    currentPersonResult,
    currentPerson,
    cartItems.length,
    planId,
    plan.data,
    firebase.sageAuth,
    returnToLatest,
    saveEntries,
    props
  ]);

  useEffect(() => {
    const cleanUp = async (): Promise<void> => {
      if (!hasInitialized && cartItems.length > 0) {
        await emptyCart();
        await clearEntries();
      }
    };
    cleanUp();
  }, [hasInitialized, cartItems.length, emptyCart, clearEntries]);

  return null;
};

export default Plan;
