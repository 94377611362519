import React from 'react';
import Welcome from '../screens/welcome';

const ScreenComponents: { [s: string]: React.ComponentType } = {
  Welcome,
  SelectLocation: React.lazy(() => import('../screens/select-location')),
  Checkout: React.lazy(() => import('../screens/checkout')),
  Success: React.lazy(() => import('../screens/success')),
  Review: React.lazy(() => import('../screens/review')),
  AccountDetails: React.lazy(() => import('../screens/account-details')),
  BillingDetails: React.lazy(() => import('../screens/billing-details')),
  PaymentPlan: React.lazy(() => import('../screens/payment-plan')),
  InsuranceRTE: React.lazy(() => import('./insurance-rte')),
  InsuranceNotEligible: React.lazy(() => import('./insurance-not-eligible'))
};

export default ScreenComponents;
