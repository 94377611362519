import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from '@apollo/react-common';
import gql from 'graphql-tag';
import { registerPaymentCard } from './generated-types/registerPaymentCard';

const REGISTER_PAYMENT_CARD_MUTATION = gql`
  mutation registerPaymentCard(
    $input: PapiRegisterPaymentCardWithPersonInput!
  ) {
    card: registerPaymentCardWithPerson(input: $input) {
      id
      name
      last4Digits
      expiryMonth
      expiryYear
    }
  }
`;

type TRegisterCard = (
  stripeTokenResponse: stripe.Token,
  dapiPersonID: string
) => Promise<ExecutionResult<registerPaymentCard>>;

export default function useRegisterCard(): TRegisterCard {
  const [registerPaymentCardMutation] = useMutation<registerPaymentCard>(
    REGISTER_PAYMENT_CARD_MUTATION
  );

  const registerCard: TRegisterCard = useCallback(
    async (stripeToken: stripe.Token, dapiPersonID: string) => {
      try {
        const token = stripeToken;

        if (!token.card) {
          throw Error('Cannot register card with DAPI, no stripe token.');
        }

        const cardData = {
          personID: dapiPersonID,
          stripeSourceToken: token.id,
          expiryMonth: token.card.exp_month,
          expiryYear: token.card.exp_year,
          last4Digits: token.card.last4,
          name: token.card.brand
        };

        return await registerPaymentCardMutation({
          variables: {
            input: cardData
          }
        });
      } catch (err) {
        throw new Error(err.message);
      }
    },
    [registerPaymentCardMutation]
  );

  return registerCard;
}
