import React, { useRef, useState } from 'react';
import { useField } from 'react-final-form';
import clsx from 'clsx';
import useCheckEmail from '../../api/use-check-email';
import { MY_PARSLEY_URL } from '../../constants/external-links';
import validateEmailFormat from '../../lib/validate-email';
import { Label } from '../atoms/label';
import { Link } from '../atoms/link';
import { TextInput } from '../atoms/text-input';

interface Props {
  name: string;
  showLogin?: boolean;
  tabIndex?: number;
  className?: string;
}

const EmailField: React.FC<Props> = props => {
  const checkEmail = useCheckEmail();
  const [validationComplete, setValidationComplete] = useState(false);
  const clear = useRef<(() => void) | null>(null);
  const validate = (email: string): string | Promise<unknown> => {
    setValidationComplete(false);
    if (!email) {
      return 'Please enter your email address.';
    } else if (!validateEmailFormat(email)) {
      return 'Email address is invalid.';
    }

    return new Promise<void | string>(resolve => {
      if (clear.current) clear.current();
      const timerId = setTimeout(() => {
        checkEmail(email).then(result => {
          if (result && result.data.emailValid) {
            resolve();
          } else {
            resolve('That email address is not valid or already in use.');
          }
          setValidationComplete(true);
        });
      }, 0);
      clear.current = () => {
        clearTimeout(timerId);
        resolve();
      };
    });
  };

  const { input, meta } = useField(props.name, {
    validate: validate,
    validateFields: [props.name]
  });

  return (
    <div className={clsx('ph-relative', props.className)}>
      <Label htmlFor="email" className="ph-mb-2">
        Email
      </Label>
      <TextInput
        {...input}
        data-testid="email"
        type="email"
        placeholder="Email"
        isValidating={meta.validating && !validationComplete}
        isValid={validationComplete && meta.valid}
        error={
          meta.error && (validationComplete || meta.touched)
            ? meta.error
            : undefined
        }
        tabIndex={props.tabIndex}
      />
      {props.showLogin && meta.error && (validationComplete || meta.touched) ? (
        <div className="ph-absolute ph-z-10 ph-right-10 ph-top-10">
          <Link href={MY_PARSLEY_URL}>Log in</Link>
        </div>
      ) : null}
    </div>
  );
};

export default EmailField;
