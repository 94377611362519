// I'm only adding 'open' since it is the only one we need at the moment
import { REACT_APP_HELPSCOUT_BEACON_ID } from '../../src/constants/env';
import LogRocket from 'logrocket';
import { track } from '@parsleyhealth/cilantro-track';

type BeaconMethod = string;
type CallbackFunction = (name: string, email: string) => void;

export type Beacon = (
  method: BeaconMethod,
  parameter?: any,
  callbackFunction?: CallbackFunction
) => void;

export const OpenBeacon = (): void => {
  window.Beacon('open');
};

export const HelpScoutIdentify = (
  nameUser: string,
  emailUser: string
): void => {
  LogRocket.getSessionURL(function(sessionURL) {
    window.Beacon('session-data', {
      name: nameUser,
      email: emailUser,
      logRocketURL: sessionURL ? sessionURL : ''
    });
  });
};

export const HelpScoutInit = (): void => {
  window.Beacon('init', REACT_APP_HELPSCOUT_BEACON_ID);
  window.Beacon('on', 'open', () => {
    track('Chat Opened', {});
  });
  window.Beacon('on', 'chat-started', (name, email) => {
    HelpScoutIdentify(name, email);
    track('Chat Started', {});
  });

  window.Beacon('on', 'close', () => {
    track('Chat Closed', {});
  });
  window.Beacon('on', 'email-sent', (name, email) => {
    HelpScoutIdentify(name, email);
    track('Chat Submitted', {});
  });
};
