import { screens } from './screens';
import { Flow } from '../types';

export const completeCareFlow: Flow = [
  screens.welcome,
  screens.location,
  screens.paymentPlan,
  screens.checkout,
  screens.review,
  screens.success
];

export const completeCareRTEFlow: Flow = [
  screens.welcome,
  screens.location,
  screens.insuranceRTE,
  screens.insuranceNotEligible,
  screens.paymentPlan,
  screens.checkout,
  screens.review,
  screens.success
];

export const checkoutFlow: Flow = [
  screens.welcome,
  screens.location,
  screens.paymentPlan,
  screens.accountDetails,
  screens.billingDetails,
  screens.success
];
