import { AnyProductSelection, PapiPlan, PapiAvailableCenters } from '../types';
import { getPlanForProductSelection } from './products';

function getAvailableCentersForProductSelection(
  selection: AnyProductSelection,
  availabilities: PapiAvailableCenters,
  allPlans: PapiPlan[]
): PapiAvailableCenters {
  const availableCenters = availabilities.filter(availability => {
    const basePlan = getPlanForProductSelection(
      selection,
      allPlans!,
      availability.center
    );
    const centerInPlan = basePlan?.joinCentersAllowed.find(
      ({ id }) => availability.center.id === id
    );

    const planInCenter = availability.plans.find(
      ({ planId }) => planId === basePlan?.id
    );

    if (centerInPlan || planInCenter) {
      return availability;
    }

    return false;
  });

  return availableCenters;
}

export default getAvailableCentersForProductSelection;
