// debug1
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import logRocketInit from './lib/log-rocket';
import { HelpScoutInit } from './lib/help-scout';

if (!window.Cypress) {
  HelpScoutInit();
  logRocketInit();
}

ReactDOM.render(<App />, document.getElementById('root'));
