import { useMemo } from 'react';
import useAllPlans from '../state/use-all-plans';
import useAvailableCenters from '../api/use-available-centers';
import { useCart, useEntries } from '.';

import { PapiPlan, PapiCenter, FilteredQueryResult } from '../types';
import getSelectedCenter from '../lib/get-selected-center';
import { getPlanForProductSelection } from '../lib/products';

type UseSelectionResult = {
  plan: PapiPlan | null;
  center: PapiCenter | null;
};

/**
 * Gets an object contaning the current select center as
 * a `PapiCenter` and currently selected plan as a `PapiPlan`.
 *
 * Returns `undefined` if graphql data is not loaded.
 *
 * Returns `{ center, plan }` where ether property will be null
 * if there is no respective selection.
 */
const useSelections = (): FilteredQueryResult<
  UseSelectionResult | undefined
> => {
  const { cartItems } = useCart();
  const { entries } = useEntries();
  const {
    data: allPlans,
    loading: productsLoading,
    error: productsError
  } = useAllPlans();
  const {
    data: availabilities,
    loading: availabilitiesLoading,
    error: availabilitiesError
  } = useAvailableCenters(entries.addressPostalCode);
  const comingFromPlanRoute = entries.comingFromPlanRoute;

  const selections = useMemo(() => {
    if (!allPlans || !availabilities) {
      return undefined;
    }

    const center = getSelectedCenter(
      entries.centerID,
      cartItems[0],
      allPlans,
      availabilities.getAvailabilities
    );

    let plan = undefined;
    if (comingFromPlanRoute) {
      plan = allPlans.find(p => p.id === cartItems[0].planID);
    } else {
      plan = getPlanForProductSelection(cartItems[0], allPlans, center);
    }

    return {
      center: center ? center : null,
      plan: plan ? plan : null
    };
  }, [
    availabilities,
    cartItems,
    entries.centerID,
    allPlans,
    comingFromPlanRoute
  ]);

  return {
    data: selections,
    loading: productsLoading || availabilitiesLoading,
    error: productsError || availabilitiesError
  };
};

export default useSelections;
