import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useFirebase } from './firebase';

const useAuth = (): { user: any; isLoggedIn: boolean } => {
  const firebase = useFirebase();
  const [user, setUser] = useState<firebase.User | null>(null);
  useEffect(() => {
    function handleAuthStateChange(user: firebase.User | null): void {
      if (user) {
        setUser(user);
      } else {
        firebase.doSignIntoSageAnonymously();
      }
    }
    firebase.sageAuth.onAuthStateChanged(handleAuthStateChange);
  }, [firebase]);
  const isLoggedIn = user !== null && !user.isAnonymous;
  return { user, isLoggedIn };
};

const Auth = createContainer(useAuth);

export default Auth;
