import { useMemo } from 'react';
import useAllPlans from '../state/use-all-plans';
import useAvailableCenters from '../api/use-available-centers';
import { useEntries } from '.';
import { PapiPlan, PapiCenter, FilteredQueryResult } from '../types';
import { getAllPlanForProduct } from '../lib/products';

/**
 * Gets an object contaning the current select center as
 * a `PapiCenter` and currently selected plan as a `PapiPlan`.
 *
 * Returns `undefined` if graphql data is not loaded.
 *
 * Returns `{ center, plan }` where ether property will be null
 * if there is no respective selection.
 */
const useValidPlansByProduct = (
  productKey: string
): FilteredQueryResult<PapiPlan[] | undefined> => {
  const { entries } = useEntries();
  const {
    data: allPlans,
    loading: productsLoading,
    error: productsError
  } = useAllPlans();
  const {
    data: availabilities,
    loading: availabilitiesLoading,
    error: availabilitiesError
  } = useAvailableCenters(entries.addressPostalCode);

  const plansByProducts = useMemo(() => {
    if (!allPlans || !availabilities) {
      return undefined;
    }
    let center: PapiCenter | undefined;
    if (entries.centerID) {
      center = availabilities.getAvailabilities.find(
        a => a.center.id === entries.centerID
      )?.center;
    }

    const planIds = availabilities.getAvailabilities.reduce((acc, center) => {
      center.plans.forEach(item => acc.add(item.planId));
      return acc;
    }, new Set<string>());

    return allPlans.reduce((acc: any, plan) => {
      if (!acc[plan.productType]) {
        acc[plan.productType] = getAllPlanForProduct(
          plan.productType,
          allPlans,
          center
        ).filter(item => planIds.has(item.id));
      }
      return acc;
    }, {});
  }, [availabilities, entries.centerID, allPlans]);

  return {
    data: plansByProducts ? plansByProducts[productKey] : undefined,
    loading: productsLoading || availabilitiesLoading,
    error: productsError || availabilitiesError
  };
};

export default useValidPlansByProduct;
