import React from 'react';
import { Pill, PillColorTheme } from '@ph-bit/design.ui.buttons.pill';

type ScreenButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  label?: string;
  dataTestId?: string;
};

const ScreenButton: React.FC<ScreenButtonProps> = ({
  disabled,
  onClick,
  label = 'continue',
  dataTestId = 'next'
}) => {
  return (
    <Pill
      data-testid={dataTestId}
      color={PillColorTheme.sage}
      onClick={onClick}
      disabled={disabled}
      className="ph-w-full md:ph-max-w-215 mx-auto ph-capitalize ph-mb-4"
    >
      {label}
    </Pill>
  );
};

export default ScreenButton;
