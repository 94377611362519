import React from 'react';
import Icon, { IconProps } from '../icon';

export const Logo: React.FC<IconProps> = props => {
  return (
    <Icon
      height={22}
      width={209}
      ariaLabel="logo parsley health icon"
      {...props}
    >
      <path
        d="m202.679 6.217c-1.596 0-3.222.522-4.297 1.688v-7.887l-.021.02-3.354 1.64v20.128h3.375v-9.666c.614-1.81 1.934-2.701 3.652-2.701 2.27 0 3.498 1.78 3.498 3.437v8.93h3.376v-8.991c0-3.959-2.424-6.598-6.23-6.598zm-22.016.246h2.27v-2.946l3.375-1.657v4.603h3.93l-.002 3.007h-3.928v6.966c0 1.565.43 2.27 2.179 2.27h2.117v3.1h-2.976c-2.946 0-4.695-1.75-4.695-4.665v-7.671h-2.27zm-7.136-4.785v20.128h3.376v-21.778zm-8.315 19.125c-1.074.859-2.486 1.35-4.112 1.35-4.542 0-7.886-3.437-7.886-7.917s3.344-7.917 7.886-7.917c1.626 0 3.038.522 4.112 1.38v-1.379h3.375v15.588h-3.375v-1.104zm0-9.176c-.829-1.257-2.394-2.086-4.081-2.086-2.609 0-4.45 2.025-4.45 4.695s1.841 4.695 4.45 4.695c1.687 0 3.252-.829 4.08-2.087v-5.217zm-30.27 2.507c0-4.48 3.375-7.917 7.855-7.917 4.266 0 7.12 3.008 7.334 7.917v.92h-11.691c.337 2.18 2.117 3.652 4.418 3.652 1.518996.0147969 2.971541-.6219203 3.99-1.749l2.27 2.424c-1.32 1.535-3.56 2.67-6.26 2.67-4.541 0-7.916-3.437-7.916-7.917zm3.59-1.688h7.886c-.399-2.086-1.841-3.13-3.866-3.13-1.81 0-3.468 1.198-4.02 3.13zm-7.15-12.446-3.559 1.74v7.28h-10.801v-9.02h-.001l-3.559 1.74v20.387h3.56v-9.668h10.801v9.668h3.56v-22.127z"
        fill="currentColor"
      />
      <path
        d="m91.647 20.916-6.045-14.545h3.621l4.173 10.586 4.205-10.586h3.62l-8.776 20.989h-3.56zm-22.86-6.659c0-4.48 3.375-7.917 7.855-7.917 4.266 0 7.12 3.007 7.334 7.917v.92h-11.691c.337 2.18 2.117 3.652 4.419 3.652 1.84 0 3.191-.86 3.989-1.749l2.27 2.424c-1.319 1.535-3.559 2.67-6.26 2.67-4.54 0-7.916-3.437-7.916-7.917zm3.59-1.688h7.886c-.399-2.087-1.84-3.13-3.866-3.13-1.81 0-3.468 1.197-4.02 3.13zm-11.108-10.778v20.32h3.375v-21.971zm-15.097 17.253 2.578-1.841c.736 1.258 1.595 1.964 3.13 1.964 1.166 0 1.964-.583 1.964-1.412 0-.89-.645-1.411-2.056-1.933l-1.074-.43c-2.609-1.012-3.744-2.639-3.744-4.725 0-2.486 2.087-4.327 4.94-4.327 1.964 0 3.652.767 4.787 2.67l-2.485 1.718c-.522-.951-1.258-1.38-2.24-1.38-.89 0-1.688.46-1.688 1.227 0 .797.522 1.289 1.964 1.871l1.105.4c2.577 1.012 4.02 2.362 4.02 4.756 0 2.7-2.24 4.572-5.493 4.572-2.425 0-4.45-.982-5.708-3.13zm-10.739-12.704h3.376v1.75c.981-1.167 2.3-1.72 3.958-1.72.399 0 .767.031 1.105.093v3.529c-.46-.154-1.044-.215-1.688-.215-1.442 0-2.7.552-3.375 1.841v10.31h-3.376zm-7.977 14.484c-1.074.859-2.486 1.35-4.112 1.35-4.542 0-7.887-3.437-7.887-7.917s3.345-7.917 7.887-7.917c1.626 0 3.038.522 4.112 1.38v-1.401h3.375v15.61h-3.375v-1.104zm0-9.176c-.829-1.257-2.394-2.086-4.082-2.086-2.608 0-4.45 2.025-4.45 4.695s1.842 4.695 4.45 4.695c1.688 0 3.253-.829 4.082-2.087zm-27.341-11.507h6.506c4.51 0 7.61 2.824 7.61 6.935 0 4.05-3.1 6.935-7.61 6.935h-2.946v7.918h-3.56v-21.789zm6.352 10.556c2.609 0 4.143-1.442 4.143-3.62 0-2.18-1.534-3.621-4.143-3.621h-2.792v7.241z"
        fill="currentColor"
      />
    </Icon>
  );
};
