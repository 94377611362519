import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

export interface LabelProps extends InputHTMLAttributes<HTMLLabelElement> {
  className?: string;
  hidden?: boolean;
  htmlFor: string;
}

export const Label: React.FC<LabelProps> = ({ className, ...labelProps }) => {
  return (
    <label
      {...labelProps}
      className={clsx(
        'ph-block',
        'ph-text-xs',
        'ph-font-sans',
        'ph-font-bold',
        'ph-uppercase',
        'ph-text-dark-green',
        className
      )}
    />
  );
};
