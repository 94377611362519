import clsx from 'clsx';
import React from 'react';
import InputCheck from '../atoms/icons/input-check';
import X from '../atoms/icons/x';
import Radar from '../atoms/radar';

export interface InputWrapperProps {
  error?: string | undefined;
  helper?: string;
  isSelect?: boolean;
  isValid?: boolean;
  isValidating?: boolean;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  children,
  error,
  helper,
  isSelect = false,
  isValid,
  isValidating
}) => (
  <div className={clsx('ph-relative')}>
    {children}
    <div
      className={clsx(
        'ph-absolute',
        'ph-top-6',
        'ph--mt-2',
        'ph-pointer-events-none',
        isSelect ? 'ph-right-8' : 'ph-right-5',
        !!isValidating ? 'ph-animate-scale-in' : 'ph-animate-bounce-out'
      )}
    >
      <Radar />
    </div>
    <div
      className={clsx(
        'ph-absolute',
        'ph-top-6',
        'ph--mt-1.5',
        'ph-pointer-events-none',
        'ph-text-error-red',
        isSelect ? 'ph-right-7' : 'ph-right-4',
        error ? 'ph-animate-bounce-in' : 'ph-animate-bounce-out'
      )}
    >
      <X />
    </div>
    <div
      className={clsx(
        'ph-absolute',
        'ph-top-6',
        'ph--mt-1.5',
        'ph-pointer-events-none',
        'ph-text-sage',
        isSelect ? 'ph-right-7' : 'ph-right-4',
        !!isValid ? 'ph-animate-bounce-in' : 'ph-animate-bounce-out'
      )}
    >
      <InputCheck />
    </div>
    {error ? (
      <p
        className="ph-my-1 ph-text-xs ph-text-error-red"
        data-testid="input-error-message"
      >
        {error}
      </p>
    ) : null}
    {helper ? <p className="ph-mt-2 ph-text-sm">{helper}</p> : null}
  </div>
);

export default InputWrapper;
