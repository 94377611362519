import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { P, H2, Box, Button } from '@parsleyhealth/cilantro-ui';
import { Default } from '../templates/default';
import { useReset, Loader } from '../../state';
import { BUY_COMLPETE_CARE } from '../../constants/external-links';
import { GenericMessageScreen } from '../molecules/generic-message-screen';
import { OpenHelpScoutLink } from '../atoms/open-help-scout-link';

const Error: React.FC<RouteComponentProps> = () => {
  const { stopLoad } = Loader.useContainer();
  const reset = useReset(BUY_COMLPETE_CARE);

  useEffect(() => {
    stopLoad();
  });

  return (
    <Default>
      <GenericMessageScreen>
        <H2>There was a problem</H2>
        <P>
          It looks like you may have already signed up for an account. If this
          is the case, please go to My Parsley Health to make changes.
        </P>
        <Box mb="m" data-testid="error">
          <P>
            If you were in the middle of signing up and don't believe you have
            completed the process, please start again.
          </P>
          <div className="ph-mt-md ph-flex ph-justify-center">
            <Button variant="primary" onClick={() => reset()}>
              Start Again
            </Button>
          </div>
        </Box>

        <P>
          If you're still having trouble,{' '}
          <OpenHelpScoutLink>
            reach our to our membership advisor team.
          </OpenHelpScoutLink>
        </P>
      </GenericMessageScreen>
    </Default>
  );
};

export default Error;
