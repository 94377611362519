import { CHECKOUT_FLOW } from '../types';
import qs from 'query-string';

export const CHECKOUT_FLOW_LABEL = 'CHECKOUT_FLOW';

export const getCheckoutFlow = (): CHECKOUT_FLOW => {
  const queryParams = qs.parse(window.location.search);

  if (queryParams.flow) {
    window.localStorage.setItem(
      CHECKOUT_FLOW_LABEL,
      queryParams?.flow as string
    );
  }

  const checkoutFlow = localStorage.getItem('CHECKOUT_FLOW');

  return checkoutFlow == CHECKOUT_FLOW.ENHANCED_BILLING
    ? CHECKOUT_FLOW.ENHANCED_BILLING
    : CHECKOUT_FLOW.DEFAULT;
};
