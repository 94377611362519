export interface Variant {
  key: string;
  weight: number;
  isDefault?: boolean;
}

/**
 * Lets you pass an array of variants and their weights, along with a random number between 1 and 100.
 * It will return a single key. The probability of returning a key is determined by it's weight.
 * For example: if you have an array of 100 keys, where 40 of them are "a", and you select a random key,
 * there is a 40% chance that it is an "a".
 * @param variants An array of variant objects. { key, weight }
 * @param rand A random number between 1 and 100.
 * @returns Variant Key
 */
export const selectVariant = (variants: Variant[], rand: number): string => {
  let selectedVariant = variants[0].key; // Initially it slects the first variant
  let count = 0;

  for (let i = 0; i < variants.length; i++) {
    const currVariant = variants[i];
    count = count + currVariant.weight;

    if (rand <= count) {
      selectedVariant = currVariant.key;
      break;
    }
  }

  return selectedVariant;
};
