import { useState, useEffect, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import moment from 'moment';
import { useFirebase } from '../state/firebase';
import useRecaptcha from './use-recaptcha';
import { FIREBASE_FUNCTION_URL } from '../constants/env';
import getCookie from '../lib/get-cookie';
import track from '@parsleyhealth/cilantro-track';

interface TUseSession {
  /**
   * Firebase auth user object.
   */
  user: firebase.User | null;
  /**
   * This variable holds the firestore document reference to the
   * user record in firestore.
   */
  fsUserRef: firebase.firestore.DocumentReference | null;
  /**
   * Resets the browser to a fresh session, done by
   * signing the user out of firebase, which will cause
   * the the app to sign in again with a new user id.
   */
  reset(): Promise<void>;
  /**
   * Creates a new session record for the user in
   * firestore.
   */
  createSession(data: {
    addressPostalCode?: string;
    stateCode: string;
    email?: string;
    privacyPolicyAcceptedAt?: string;
    termsOfUseAcceptedAt?: string;
  }): Promise<void>;
  /**
   * Whether or not a record exists for the current user in firestore.
   */
  hasSessionRecord: boolean;
}

/**
 * A react hook that provides and manages data related to the
 * users session state, which includes saved entry data and
 * cart information.
 */
function useSession(): TUseSession {
  const firebase = useFirebase();
  const getToken = useRecaptcha();
  const [user, setUser] = useState<firebase.User | null>(null);
  const [hasSessionRecord, setHasSessionRecord] = useState(false);

  const init = useCallback(
    async function(): Promise<void> {
      try {
        let fbUser = await firebase.checkUser();
        if (fbUser === null) {
          const result = await firebase.doSignInAnonymously();
          if (result.user) {
            fbUser = result.user;
          }
        }

        if (fbUser) {
          // Check if there is an existing session record
          // for this user
          const doc = await firebase.db
            .collection('users')
            .doc(fbUser.uid)
            .get();

          if (doc.exists) {
            setHasSessionRecord(true);
            const startTime = moment(doc.get('lastUsage'));
            const end = moment(new Date().toISOString());
            const duration = moment.duration(end.diff(startTime));
            const hours = duration.asHours();
            if (hours > 48 && doc.get('cart').length > 0) {
              await firebase.db
                .collection('users')
                .doc(fbUser.uid)
                .update({ lastUsage: new Date().toISOString(), cart: [] });
            }
          }
          setUser(fbUser);
        }
      } catch (err) {
        throw err;
      }
    },
    [firebase]
  );

  const createSession = useCallback(
    async function(data: {
      addressPostalCode: string;
      stateCode: string;
      email?: string;
      privacyPolicyAcceptedAt?: string;
      termsOfUseAcceptedAt?: string;
    }): Promise<void> {
      if (getToken && user && !hasSessionRecord) {
        const recaptchaResponse = await getToken();
        const fsUserRef = firebase.getFsUserRef();
        const userId = user.uid;
        // TODO: is this required still?
        const hutk = getCookie('hubspotutk');

        if (recaptchaResponse && fsUserRef) {
          try {
            const sessionData = {
              recaptchaResponse,
              userId,
              addressPostalCode: data.addressPostalCode,
              stateCode: data.stateCode,
              email: data.email,
              privacyPolicyAcceptedAt: data.privacyPolicyAcceptedAt,
              termsOfUseAcceptedAt: data.termsOfUseAcceptedAt,
              // TODO: is this field required still?
              ...(hutk && { hutk })
            };

            await fetch(`${FIREBASE_FUNCTION_URL}/createSession`, {
              method: 'POST',
              body: JSON.stringify(sessionData),
              headers: {
                'Content-Type': 'application/json'
              }
            });
            // Important - this registers that the session record was successful.
            setHasSessionRecord(true);
          } catch (err) {
            track('Join Flow Error', {
              product: undefined,
              description: `Session error: ${err.message}`
            });
          }
        }
      }
    },
    [firebase, getToken, user, hasSessionRecord]
  );

  const reset = useCallback(async (): Promise<void> => {
    await firebase.doSignOut();
    setUser(null);
    setHasSessionRecord(false);
  }, [firebase]);

  useEffect(() => {
    if (user === null) {
      init();
    }
  }, [init, user]);

  const fsUserRef = firebase.getFsUserRef();

  return {
    user,
    fsUserRef,
    reset,
    createSession,
    hasSessionRecord
  };
}

const Session = createContainer(useSession);

export default Session;
