import clsx from 'clsx';
import React from 'react';
import P from '../atoms/p';
import { useCounter, useNavigate } from '../../state';
import { TempLegalFooter } from '../organisms/temp-legal-footer';

interface LayoutProps {
  centered?: boolean;
  testid: string;
}

const ScreenWrapper: React.FC<LayoutProps> = ({
  centered = false,
  children,
  testid
}) => {
  const { currentScreen } = useNavigate();

  const [completedScreen, screens] = useCounter();

  if (currentScreen === undefined) {
    return null;
  }

  return (
    <div
      className="ph-main-gradient ph-page-wrapper min-h-screen ph-pt-4 md:ph-pt-8"
      data-testid={testid}
    >
      <div
        className={clsx(
          'ph-pt-28 ph-flex-grow ph-mb-4',
          centered && 'ph-flex ph-flex-col ph-justify-center ph-items-center'
        )}
      >
        {currentScreen.hideCounter !== true && (
          <P className="ph-hidden md:ph-block ph-font-bold md:ph-text-center">
            {completedScreen + 1}/{screens + 1}
          </P>
        )}
        {children}
      </div>
      <TempLegalFooter />
    </div>
  );
};

export default ScreenWrapper;
