import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { PapiMakeNewPurchaseInput } from '../types/papi-global-types';
import {
  makeNewPurchase,
  makeNewPurchaseVariables
} from './generated-types/makeNewPurchase';
const MAKE_NEW_PURCHASE = gql`
  mutation makeNewPurchase($input: PapiMakeNewPurchaseInput!) {
    purchase: makeNewPurchase(input: $input) {
      planPurchase {
        id
        plan {
          displayName
          description
          billingPeriod
          monthsDuration
          totalPaymentInCents
          annualCostInCents
          commitmentLength
        }
        startTime
        endTime
        paymentDueDate
        paymentStatus
        nextRenewalDate
        commitmentEndDate
      }
    }
  }
`;

export default function useMakePurchase(): (
  purchaseData: PapiMakeNewPurchaseInput
) => Promise<makeNewPurchase | undefined> {
  const [makeNewPurchaseMutation] = useMutation<
    makeNewPurchase,
    makeNewPurchaseVariables
  >(MAKE_NEW_PURCHASE);
  return async (purchaseData: PapiMakeNewPurchaseInput) => {
    const result = await makeNewPurchaseMutation({
      variables: {
        input: purchaseData
      }
    });
    return result.data;
  };
}
