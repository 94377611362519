import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import sleep from '../lib/sleep';

type LoaderType = 'purchase' | 'normal' | 'error' | 'insurance-rte';

interface TUseLoader {
  loading: boolean;
  loaderType: LoaderType;
  startLoad(type?: LoaderType): Promise<void>;
  stopLoad(): void;
}

/**
 * This hook is used to make a state container for the global
 * "loader" state. There is a single loader overlay that is displayed as the user
 * navgiates and submits data. The loader state is also capable of
 * handling error states too, see `useWaitFor()`.
 */
function useLoader(): TUseLoader {
  const [loading, setLoading] = useState(true);
  const [loaderType, setloaderType] = useState<LoaderType>('normal');
  const startLoad = useCallback((type?: LoaderType): Promise<void> => {
    if (type) {
      setloaderType(type);
    } else {
      setloaderType('normal');
    }
    setLoading(true);
    // The sleep here allows us to wait for the loader to animate into view
    // before we take the next step
    return sleep(500);
  }, []);

  const stopLoad = useCallback((): void => {
    setLoading(false);
  }, []);

  return { loading, loaderType, startLoad, stopLoad };
}

const Loader = createContainer(useLoader);

export default Loader;
