import { useMemo } from 'react';
import { createContainer } from 'unstated-next';
import ScreensManager from '../lib/screens-manager';
import {
  checkoutFlow,
  completeCareFlow,
  completeCareRTEFlow
} from '../constants/flows';
import { AnyCartItem, Flow, CHECKOUT_FLOW } from '../types';
import { useCart } from './cart';
import { getProductProps, getRTEProductProps } from '../lib/products';

interface TUseScreens {
  manager: ScreensManager;
}

const joinFlow = completeCareFlow;
const joinRTEFlow = completeCareRTEFlow;

export const getFlowType = (): string | null | undefined => {
  try {
    const body = document.getElementsByTagName('body')[0];
    let flowType;

    // join-flow is added from vwo

    for (let i = 0; i < body.attributes.length; i++) {
      const attrib = body.attributes[i];
      if (attrib.name === 'join-flow') {
        flowType = attrib.value;
        break;
      }
    }

    return flowType;
  } catch (error) {
    return null;
  }
};

/**
 * The sequence of screens in the flow may differ based on
 * the product selected. This function calculates the correct
 * flow given the current cart state.
 * @param cartItems Your current cart
 * @returns A {@link ScreensManager} object
 */
function defaultGetFlow(cartItems: AnyCartItem[]): Flow {
  if (cartItems.length === 0) {
    return joinFlow;
  }
  if (cartItems[0].params.checkoutFlow === CHECKOUT_FLOW.ENHANCED_BILLING) {
    return checkoutFlow;
  }

  const productProps = getProductProps(cartItems[0].productKey);
  const slug = cartItems[0].slug;
  if (!slug || !productProps) {
    if (!productProps) console.error('INVALID PRODUCT');
    return joinFlow;
  }
  const product = productProps.flows.find(f => f.slug === slug);
  if (!product) {
    return joinFlow;
  }
  return product.flow;
}

function defaultGetRTEFlow(cartItems: AnyCartItem[]): Flow {
  if (cartItems.length === 0) {
    return joinRTEFlow;
  }
  if (cartItems[0].params.checkoutFlow === CHECKOUT_FLOW.ENHANCED_BILLING) {
    return checkoutFlow;
  }

  const productProps = getRTEProductProps(cartItems[0].productKey);
  const slug = cartItems[0].slug;
  if (!slug || !productProps) {
    if (!productProps) console.error('INVALID PRODUCT');
    return joinRTEFlow;
  }
  const product = productProps.flows.find(f => f.slug === slug);
  if (!product) {
    return joinRTEFlow;
  }
  return product.flow;
}

function useScreens(getFlow = defaultGetFlow): TUseScreens {
  const cart = useCart();

  getFlow = defaultGetRTEFlow;

  const manager = useMemo(() => new ScreensManager(getFlow(cart.cartItems)), [
    cart.cartItems,
    getFlow
  ]);
  return { manager };
}

const Screens = createContainer(useScreens);

export default Screens;
