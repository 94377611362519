import clsx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import InputWrapper, { InputWrapperProps } from '../molecules/input-wrapper';

export interface TextInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    InputWrapperProps {
  forceNumericKeyboardOnMobile?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  error,
  isValid,
  isValidating,
  helper,
  forceNumericKeyboardOnMobile,
  ...inputProps
}) => (
  <InputWrapper
    error={error}
    helper={helper}
    isValid={isValid}
    isValidating={isValidating}
  >
    <input
      className={clsx(
        'form-input',
        'ph-box-border',
        'ph-border-none',
        'ph-rounded-xl',
        'ph-block',
        'ph-w-full',
        'ph-p-4',
        'ph-font-sans',
        'ph-text-sm',
        'ph-bg-white',
        'ph-ring-1',
        error
          ? 'ph-text-error-red ph-ring-error-red focus:ph-ring-error-red'
          : 'ph-text-dark-green ph-ring-white focus:ph-ring-sage'
      )}
      pattern={forceNumericKeyboardOnMobile ? '[0-9]*' : undefined}
      inputMode={forceNumericKeyboardOnMobile ? 'numeric' : undefined}
      {...inputProps}
    />
  </InputWrapper>
);
