import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { plan } from './generated-types/plan';
import gql from 'graphql-tag';

export const GET_PLAN = gql`
  query plan($id: ID!) {
    plan(id: $id) {
      annualCostInCents
      billingPeriod
      commitmentLength
      defaultFollowOnPlanID
      description
      displayName
      id
      insuranceType
      joinCentersAllowed {
        id
        isVirtual
        city
        name
        state
        addressLine1
        addressLine2
        key
      }
      maxPersonAgeYears
      minPersonAgeYears
      numberOfBillingCycles
      paymentRateInCents
      totalPaymentInCents
      product {
        type
        membershipType
        displayName
        abbreviation
        renewalPeriod
        sanityProduct {
          productName
        }
      }
    }
  }
`;

const usePlan = (
  id: string | undefined
): QueryResult<plan, Record<string, any>> => {
  return useQuery(GET_PLAN, {
    variables: { id }
  });
};
export default usePlan;
