import { useMemo } from 'react';
import {
  CCY1_MONTH_199,
  CCY1_YEAR_2189,
  V_CCY1_MONTH_199,
  V_CCY1_YEAR_2189
} from '../constants/hard-coded-plan-ids';

enum LocationType {
  VIRTUAL = 'VIRTUAL',
  IN_PERSON = 'IN_PERSON'
}

type plansByLocationType = Record<LocationType, string>[];

//This object will be retrived from sanity
const plansByLocation: plansByLocationType = [
  {
    // What is this
    VIRTUAL: '8a46ba9c-34f9-491c-ae3d-6234736f8999',
    IN_PERSON: '3367302d-8150-4d52-bdbb-ee462f8dd666'
  },
  // Variant 1
  {
    VIRTUAL: V_CCY1_MONTH_199,
    IN_PERSON: CCY1_MONTH_199
  },
  // Variant 2
  {
    VIRTUAL: V_CCY1_YEAR_2189,
    IN_PERSON: CCY1_YEAR_2189
  }
];

const useGetMatchingLocationPlan = (
  currentPlanId: string | undefined,
  isVirtual: boolean
): string | undefined => {
  const planId = useMemo(() => {
    const locationObject = plansByLocation.find(p => {
      return Object.values(p).find(v => v === currentPlanId);
    });

    if (locationObject === undefined) return;

    if (isVirtual) return locationObject.VIRTUAL;

    return locationObject.IN_PERSON;
  }, [plansByLocation, currentPlanId]);

  return planId;
};

export default useGetMatchingLocationPlan;
