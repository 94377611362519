import { useCallback } from 'react';
import useAvailableCenters from '../api/use-available-centers';
import { PapiCenter, PapiPlan, FilteredQueryResult } from '../types';
import { useEntries } from './entries';

function useIsWaitlisted(): FilteredQueryResult<
  (
    selectedCenter: Partial<PapiCenter>,
    selectedPlan: Partial<PapiPlan>
  ) => string | undefined | null
> {
  const { entries } = useEntries();
  const { data: availabilities, loading, error } = useAvailableCenters(
    entries.addressPostalCode
  );
  const isWaitlisted = useCallback(
    (selectedCenter, selectedPlan) => {
      const selectedAvailability = availabilities?.getAvailabilities?.find(
        availability => availability.center.id === selectedCenter.id
      );

      const selectedPlanFromAvailability = selectedAvailability?.plans.find(
        ({ planId }) => planId === selectedPlan.id
      );

      return selectedPlanFromAvailability?.waitlistedPlanId;
    },
    [availabilities]
  );

  return {
    data: isWaitlisted,
    loading,
    error
  };
}

export default useIsWaitlisted;
