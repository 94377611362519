import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import {
  Screens,
  Auth,
  useCart,
  useEntries,
  useUpdateEntries,
  useReset,
  useNavigate
} from '../../state';
import { QueryParams } from '../../types';
import { BUY_PATH, ERROR_PATH, PLAN_PATH } from '../../constants/route-names';
import { screens as screensConfig } from '../../constants/screens';
import { setupSplitTest } from '../../lib';
import { identify } from '@parsleyhealth/cilantro-track';

/**
 * A wrapper component used to perform initial redirects
 * to bring the user to where they are up to, set up redirects, absorb the query
 * string and
 */
const Bootstrap: React.FC = props => {
  const screens = Screens.useContainer();
  const auth = Auth.useContainer();
  const {
    returnToLatest,
    getScreenIndex,
    getReturningScreenIndex,
    currentScreen,
    goToLastScreen
  } = useNavigate();
  const { entries } = useEntries();
  const { saveEntries } = useUpdateEntries();
  const { cartItems } = useCart();
  const reset = useReset();
  const [hasBootstrapped, setHasBootstrapped] = useState<boolean>(false);

  /**
   * This effect runs whenever the app is mounted
   * (i.e. page is loaded), and its purpose is to take the user to the correct
   * place given their saved session information and query parameters.
   */
  useEffect(() => {
    if (!hasBootstrapped && auth.user !== null && entries !== null) {
      const params: QueryParams = queryString.parse(window.location.search);

      if (params.coupon) {
        saveEntries({ couponCode: params.coupon });
      }

      if (params.utm_campaign) {
        saveEntries({
          utmCampaign: params.utm_campaign,
          utmContent: params.utm_content,
          utmMedium: params.utm_medium,
          utmSource: params.utm_source
        });
      }
      // Split testing
      // Force values if they're passed in the url
      // @TODO: handle arrays of split tests in params
      if (params.st && params.stv) {
        setupSplitTest(params.st, params.stv);
      }

      // Condition Specific
      if (params.condition) {
        saveEntries({
          condition: params.condition
        });
      }

      if (params.utm_source === 'friendbuy') {
        identify({
          REFERRED_BY_FRIEND: true
        });
      }

      // These are "error" states - the flow won't function correctly with these
      // states so we direct to a special error page that lets the user start again
      // or go to My Parsley
      if (
        // User signed up at some point, logged out but didn't clear their session
        (!auth.isLoggedIn && entries.dapiPersonID) ||
        // User signed up at some point, session was cleared but they are still logged in
        (auth.isLoggedIn && !entries.dapiPersonID && !params.token)
      ) {
        navigate(ERROR_PATH);
      } else if (
        !params.token &&
        !window.location.pathname.startsWith(BUY_PATH) &&
        !window.location.pathname.startsWith(PLAN_PATH)
      ) {
        const returning = screens.manager.getReturningScreen(
          entries,
          cartItems
        );
        if (returning) {
          // Navigates to the latest step we're up to
          returnToLatest();
        }
      } else if (
        (params.token ||
          window.location.pathname.startsWith(BUY_PATH) ||
          window.location.pathname.startsWith(PLAN_PATH)) &&
        entries.purchaseID
      ) {
        reset();
      }
      setHasBootstrapped(true);
    }
  }, [
    cartItems,
    entries,
    saveEntries,
    hasBootstrapped,
    returnToLatest,
    screens.manager,
    auth.user,
    auth.isLoggedIn,
    auth,
    reset
  ]);

  /**
   * This effect runs every time the entries or cart are updated,
   * and will run only after the initial bootstrap above has run.
   * While the effect above takes care of routing the user to the
   * right screen on page load, this effect protects against
   * the user navigating to disallowed screens using the back
   * button or other means.
   */
  useEffect(() => {
    if (hasBootstrapped) {
      // If I have a successful purchase I should always be
      // on the success screen.
      if (
        entries.dapiPersonID &&
        entries.purchaseID &&
        entries.paidLatestInvoice
      ) {
        if (currentScreen?.pathname !== screensConfig.success.pathname) {
          goToLastScreen();
        }
      }

      // If we are on a screen that is further than what we're up to, return to latest
      if (getScreenIndex() > getReturningScreenIndex()) {
        returnToLatest();
      }
    }
  }, [
    entries,
    cartItems,
    getScreenIndex,
    getReturningScreenIndex,
    returnToLatest,
    hasBootstrapped,
    currentScreen,
    goToLastScreen
  ]);

  if (!hasBootstrapped) {
    return null;
  }
  return <>{props.children}</>;
};

export default Bootstrap;
