import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { getAvailabilities } from './generated-types/getAvailabilities';
import gql from 'graphql-tag';

export const AVAILABLE_CENTERS = gql`
  query getAvailabilities($zip: String!) {
    getAvailabilities(zip: $zip) {
      center {
        id
        name
        key
        isVirtual
        addressLine1
        addressLine2
        city
        state
        postalCode
      }
      plans {
        planId
        waitlistedPlanId
      }
      isWithinRange
    }
  }
`;

/**
 * Returns all available centers for a zipCode
 * @param zip zipcode
 * @returns
 */
const useAvailableCenters = (
  zip?: string
): QueryResult<getAvailabilities, any> => {
  return useQuery(AVAILABLE_CENTERS, {
    variables: {
      zip
    }
  });
};

export default useAvailableCenters;
