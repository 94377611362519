import { useApolloClient } from '@apollo/react-hooks';
import { ApolloQueryResult } from 'apollo-client';
import { useCallback } from 'react';
import gql from 'graphql-tag';
import { getStateForZipCode } from './generated-types/getStateForZipCode';

export const GET_STATE_FROM_ZIP = gql`
  query getStateForZipCode($zip: String!) {
    getStateForZipCode(zip: $zip) {
      abbreviation
      name
    }
  }
`;

type TValidateZip = {
  (zip: string): Promise<ApolloQueryResult<getStateForZipCode> | undefined>;
};

const useStateFromZipCode = (): TValidateZip => {
  const client = useApolloClient();
  const checkZipcode = useCallback(
    (zip: string) => {
      async function validateZipcode(
        zip: string
      ): Promise<ApolloQueryResult<getStateForZipCode> | undefined> {
        try {
          const result = client.query<getStateForZipCode>({
            query: GET_STATE_FROM_ZIP,
            variables: {
              zip
            }
          });
          return result;
        } catch (err) {
          return undefined;
        }
      }
      return validateZipcode(zip);
    },
    [client]
  );
  return checkZipcode;
};

export default useStateFromZipCode;
