import LogRocket from 'logrocket';
import { LOG_ROCKET_APP_ID } from '../constants/env';

function logRocketInit(): void {
  if (LOG_ROCKET_APP_ID) {
    LogRocket.init(LOG_ROCKET_APP_ID);
  } else {
    console.warn('Could not initialize Log Rocket. App Id is undefined.');
  }
}

export default logRocketInit;
