import React from 'react';
import { Header } from '../molecules/header';
import ProgressBar from '../molecules/progress-bar';

export const Default: React.FC = ({ children }) => {
  return (
    <>
      <div className="ph-fixed ph-w-full ph-z-10 ph-left-0 ph-top-0 ph-bg-pianissimo">
        <Header />
        <div className="ph-px-9 md:ph-px-28 ph-mt-6">
          <ProgressBar />
        </div>
      </div>
      <div className="ph-min-h-screen ph-h-header-top">{children}</div>
    </>
  );
};
