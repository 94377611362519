import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'react-final-form';
import { StateState } from '../screens/welcome';
import useStateFromZipCode from '../../api/use-state-from-zip-code';
import { Label } from '../atoms/label';
import { TextInput, TextInputProps } from '../atoms/text-input';

interface Props extends TextInputProps {
  name: string;
  setState(state?: StateState): void;
  state: StateState | undefined;
  className?: string;
}

const ZipField: React.FC<Props> = props => {
  const checkZipcode = useStateFromZipCode();
  const [validationComplete, setValidationComplete] = useState(false);
  const stateCodeField = useField('stateCode', {
    validateFields: []
  });
  const clear = useRef<(() => void) | null>(null);

  const validate = useCallback(
    async (zip: string) => {
      setValidationComplete(false);
      if (!zip) {
        return 'Please enter a US zip code.';
      } else if (zip.length < 5) {
        return 'Zip code must be 5 digits.';
      }

      return new Promise<void | string>(resolve => {
        if (clear.current) clear.current();
        const timerId = setTimeout(() => {
          checkZipcode(zip).then(result => {
            const state = result?.data?.getStateForZipCode;
            if (!state) {
              return resolve('Please enter a valid US zip code');
            }
            props.setState(state);
            stateCodeField.input.onChange(state.abbreviation);
            resolve();
          });
        }, 0);
        setValidationComplete(true);
        clear.current = () => {
          clearTimeout(timerId);
          resolve();
        };
      });
    },
    [checkZipcode, props, stateCodeField.input]
  );

  const { input, meta } = useField(props.name, {
    validate: validate,
    validateFields: [props.name],
    initialValue: '',
    format: (value: string) => {
      if (value === undefined) {
        return '';
      }
      // Makes sure we can only type digits
      return value.replace(/[^\d.-]/g, '');
    }
  });

  return (
    <div className={clsx('ph-relative', props.className)}>
      <Label htmlFor="addressPostalCode" className="ph-mb-2">
        Zip Code
      </Label>
      <TextInput
        data-testid="zip"
        disabled={props.disabled}
        type="text"
        maxLength={5}
        {...input}
        placeholder="Zip Code"
        isValidating={meta.validating && !validationComplete}
        isValid={props.state && meta.valid && validationComplete}
        error={
          meta.error && validationComplete && meta.visited && !meta.valid
            ? meta.error
            : undefined
        }
        forceNumericKeyboardOnMobile={true}
        helper={props.helper}
      />
      {props.state && meta.valid && validationComplete ? (
        <div
          className={clsx(
            'ph-text-xs',
            'ph-absolute',
            'ph-right-8',
            'ph-top-11',
            'ph-pointer-events-none',
            'ph-text-sage'
          )}
        >
          {props.state.name}
        </div>
      ) : null}
    </div>
  );
};

export default ZipField;
