import { Screen, EntriesData, Cart, Flow } from '../types';

class ScreensManager {
  readonly flow: Flow;
  constructor(flow: Flow) {
    this.flow = flow;
  }
  /**
   * Checks if we are allowed to view the screen for the given pathname.
   * We can't view it if there are previous screens that still require entry.
   * @param pathname
   * @param entries
   * @returns Whether we can view or not
   */
  canView = (
    pathname: string,
    entries: Partial<EntriesData>,
    cartItems: Cart
  ): boolean => {
    const index = this.flow.findIndex(screen => screen.pathname === pathname);
    if (index === -1) {
      return false;
    }
    const previousScreens = this.flow.slice(0, index);
    return !previousScreens.some(screen =>
      screen.entryRequired(entries, cartItems)
    );
  };

  /**
   * Gets the Screen index for the current window location
   * @param entries
   * @returns The current screen index
   */
  getScreenIndex = (pathname: string): number => {
    return this.flow.findIndex(screen => screen.pathname === pathname);
  };

  /**
   * Gets the Screen object for the passed path
   * @param entries
   * @returns The current screen
   */
  getScreen = (pathname: string): Screen | undefined => {
    return this.flow.find(screen => screen.pathname === pathname);
  };

  /**
   * Gets the screen we should return to given the current entries
   * @param entries
   * @returns The screen to return to
   */
  getReturningScreen = (
    entries: Partial<EntriesData>,
    cartItems: Cart
  ): Screen | undefined => {
    const screen = this.flow.find(screen =>
      screen.entryRequired(entries, cartItems)
    );
    if (screen === undefined) {
      return this.flow[this.flow.length - 1];
    }
    return screen;
  };

  /**
   * Gets the first screen in the flow
   * @returns The first screen
   */
  getStartingScreen = (): Screen => {
    if (this.flow[0] !== undefined) {
      return this.flow[0];
    }
    throw Error(
      'Screens Manager: You must have at least one screen in your flow.'
    );
  };

  /**
   * Gets the last screen in the flow
   * @returns The last screen
   */
  getLastScreen = (): Screen => {
    if (this.flow[0] !== undefined) {
      return this.flow[this.flow.length - 1];
    }
    throw Error(
      'Screens Manager: You must have at least one screen in your flow.'
    );
  };

  /**
   * Gets the next screen for forward navigation
   * @param currentPathname
   * @returns The next screen
   */
  getNextScreen = (currentPathname: string): Screen | null => {
    const index = this.getScreenIndex(currentPathname);

    if (index !== -1 && index < this.flow.length - 1) {
      return this.flow[index + 1];
    }
    return null;
  };

  /**
   * Gets the previous screen for backward navigation
   * @param currentPathname
   * @returns The previous screen
   */
  getPrevScreen = (currentPathname: string): Screen | null => {
    const index = this.getScreenIndex(currentPathname);

    if (index !== -1 && index > 0) {
      return this.flow[index - 1];
    }
    return null;
  };

  /**
   * Gets the counter based on number entries vs required
   * @param entries
   * @param cartItems
   * @returns The counter as an array, [completedScreens, totalRequiredScreens]
   */
  getCounter = (
    entries: Partial<EntriesData>,
    cartItems: Cart,
    currentPathname?: string
  ): [number, number] => {
    const progressScreens = this.flow.filter(s =>
      s.includeInProgress(entries, s.pathname)
    );
    const index = this.getScreenIndex(currentPathname || '');
    const numCompletedScreens = progressScreens.reduce((curr, screen) => {
      const i = this.getScreenIndex(screen.pathname);
      if (i >= index && screen.includeInProgress(entries, screen.pathname)) {
        return curr + 0;
      }
      return curr + (screen.entryRequired(entries, cartItems) ? 0 : 1);
    }, 0);
    return [numCompletedScreens, progressScreens.length];
  };

  /**
   * Gets the progress based on number entries vs required
   * @param currentPathname
   * @returns The progress as a fraction
   */
  getProgress = (
    entries: Partial<EntriesData>,
    cartItems: Cart,
    currentPathname?: string
  ): number => {
    const [numCompletedScreens, progressScreens] = this.getCounter(
      entries,
      cartItems,
      currentPathname
    );

    return numCompletedScreens / progressScreens;
  };
}

export default ScreensManager;
